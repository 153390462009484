import React, { useEffect } from "react";

import GDPRImg from "../../../images/GDPR.svg";

function GDPR() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "GDPR | 300media - Web and Mobile Applications Development";
  }, []);
  return (
    <section className="legal-section container">
      {/* <img src={GDPRImg} alt="GDPR" width={250} /> */}
      <h2 className="mt-4">GDPR Compliance</h2>
      <hr />
      <div className="legal-wrap">
        <h5>Introduction</h5>
        <p>
          This policy sets out how 300 Media Limited (“300 Media”) will process
          data and the rights of data subjects under data protection law,
          including in the EU the General Data Protection Regulation (GDPR).
        </p>
        <p>
          The Data Controller is 300 Media Limited, trading as 300 Media,
          company registration number 08835058. Our registered office address is
          2 Silverwood Avenue, Chorlton-cum-Hardy, Manchester, M21 8BN, United
          Kingdom.
        </p>
        <p>
          The named person responsible for data protection matters is Macs
          Mahlangu.
        </p>
        <h5>Data Processing</h5>
        <p>
          300 Media collects and processes personal data received directly from
          data subjects when such data subjects request information on the
          company’s services or interact with the company in any other way.
        </p>
        <p>
          300 Media only processes personal data for specific purposes with a
          lawful basis as notified to the data subject, or for other purposes
          permitted by data protection legislation (including the GDPR). The
          purposes for which we process personal data will be informed to data
          subjects at the time that their personal data is collected, where it
          is collected directly from them, or as soon as possible, and within
          one calendar month, after collection where it is obtained from a
          third-party.
        </p>
        <p>
          Consent: Informed consent shall be obtained when personal data is
          collected, and individuals will be given the opportunity to opt-out of
          marketing communications from 300 Media. Under this policy, 300 Media
          and anyone working on our behalf, shall explain the purpose for
          processing data and how it will be used, and the lawful basis on which
          it is processed. Any personal data that is collected will be stored
          securely and confidentially. Information and records relating to
          clients will be stored securely and only be accessible to authorised
          personnel.
        </p>
        <p>
          Accuracy of Data: 300 Media shall ensure that all personal data
          collected and processed is kept accurate and up-to-date. The accuracy
          of data shall be checked when it is collected. If we become aware of
          any inaccuracies or find that data is out-of-date, we will take
          reasonable steps to amend or erase that data, as appropriate and
          without undue delay.
        </p>
        <p>
          Timely Processing: 300 Media shall not keep personal data for any
          longer than is necessary for the purpose for which it was originally
          collected and processed. When the data is no longer required, all
          reasonable steps will be taken to erase it without undue delay.
        </p>
        <p>
          Secure Processing: 300 Media shall take appropriate steps to ensure
          that all personal data collected and processed is kept secure and
          protected against unauthorised or unlawful processing and against
          accidental loss, destruction or damage.
        </p>
        <p>
          Where special categories of personal data are processed, extra
          measures are taken to ensure that information disclosed relating to
          individuals is handled appropriately. We will undertake privacy impact
          assessments in the use of technology and any changes to our processes
          and procedures in order to ensure the security of personal data.
        </p>
        <span>
          Accountability: 300 Media will keep appropriate records of all
          personal data collected and processed, which shall include the
          following information:
        </span>
        <ul>
          <li>The purposes for which the we process personal data</li>
          <li>
            Details of the categories of personal data collected, held, and
            processed by us and the categories of data subject to which that
            personal data relates
          </li>
          <li>
            Details (and categories) of any third parties that will receive
            personal data from the 300 Media
          </li>
          <li>
            Details of any transfers of personal data to non-EEA countries
            including all mechanisms and security safeguards
          </li>
          <li>Details of how long personal data will be retained</li>
          <li>
            Details of technical and organisational measures taken by 300 Media
            to ensure the security of personal data
          </li>
        </ul>
        <h5>Privacy policy</h5>
        <p>
          The 300 Media Privacy Policy is published on our website and
          incorporates the principles of this data protection policy and
          addresses our obligations under data protection law applicable in the
          UK. Our website is hosted by Amazon Web Service, see:
          https://www.amazon.co.uk/gp/help/customer/display.html?ie=UTF8&nodeId=201909010&ref_=footer_privacyWhen
          users visit our website we will automatically process and record
          information such as IP address, time and date of visit, users’
          computer operating system, and the type of web browser used. This
          information is used in an anonymised way to make decisions about how
          to improve our services. Further details are explained in our Privacy
          Policy and Cookies Policy.
        </p>
        <p>
          When users visit our website we will automatically process and record
          information such as IP address, time and date of visit, users’
          computer operating system, and the type of web browser used. This
          information is used in an anonymised way to make decisions about how
          to improve our services. Further details are explained in our Privacy
          Policy and Cookies Policy.
        </p>
        <p>
          We ask for consent for marketing communications when users register
          for our services and users are able to withdraw their consent at any
          time via the settings on the website. We do not send unsolicited
          emails to personal email addresses.
        </p>
        <h5>Cookies Policy</h5>
        <p>
          300 Media uses cookies to enable our systems to recognise a user’s
          browser or device and to provide our services. Some cookies are sent
          from third parties, while others are sent by services used to enhance
          our website. Non-necessary cookies are used on the basis of a user’s
          consent and may be withdrawn at any time by changing the users’
          settings on the website, or by contacting us by email. Details of our
          cookies are contained in our Cookie Policy available on our website.
        </p>
        <h5>Rights of data subjects</h5>
        <p>
          Subject Access Requests: A data subject may make a subject access
          request (SAR) at any time to find out more about the personal data
          which the Company holds about them. All requests will be logged as
          soon as received by us. Any release of personal data will be checked
          to see whether it reveals the identity of a third-party. This
          information can either be redacted, or the third party will be
          contacted to consent to the release of the information. Personal data
          will only be released to the person seeking access to their data, or
          someone who has written consent from the individual to receive the
          data.
        </p>
        <p>
          300 Media is normally required to respond to a SAR within one month of
          receipt. This can be extended by up to two months in the case of
          complex and/or numerous requests, and in such cases the data subject
          shall be informed of the need for the extension.
        </p>
        <p>
          All subject access requests received must be forwarded to
          privacy@300media.co.uk.
        </p>
        <p>
          300 Media does not charge a fee for the handling of normal SARs. We
          reserve the right to charge reasonable fees for additional copies of
          information that has already been supplied to a data subject, and for
          requests that are manifestly unfounded or excessive, particularly
          where such requests are repetitive.
        </p>
        <p>
          If 300 Media decides to refuse a subject access request, we will write
          to the data subject to explain our reason, within one month of
          receiving the request.
        </p>
        <p>
          Rectification of Personal Data: If a data subject informs us that
          personal data held by 300 Media is inaccurate or incomplete,
          requesting that it be rectified, the personal data in question shall
          be rectified, and the data subject informed of that rectification,
          within one month of receipt the data subject’s notice. This can be
          extended by up to two months in the case of complex requests, and in
          such cases the data subject shall be informed of the need for the
          extension.
        </p>
        <p>
          If a data subject informs us that personal data held by 300 Media is
          inaccurate or incomplete, requesting that it be rectified, the
          personal data in question shall be rectified, and the data subject
          informed of that rectification, within one month of receipt the data
          subject’s notice. This can be extended by up to two months in the case
          of complex requests, and in such cases the data subject shall be
          informed of the need for the extension.
        </p>
        <span>
          Erasure of Personal Data: Data subjects may request that 300 Media
          erase the personal data it holds about them in the following
          circumstances:
        </span>
        <ul>
          <li>
            It is no longer necessary for 300 Media to hold that personal data
            with respect to the purpose for which it was originally collected or
            processed
          </li>
          <li>
            The data subject wishes to withdraw their consent to 300 Media
            holding and processing their personal data
          </li>
          <li>
            The data subject objects to 300 Media holding and processing their
            personal data and there is no overriding legitimate interest to
            allow the Company to continue doing so
          </li>
          <li>The personal data has been processed unlawfully</li>
          <li>
            The personal data needs to be erased in order for 300 Media to
            comply with a particular legal obligation
          </li>
          <li>
            Unless the Company has reasonable grounds to refuse to erase
            personal data, all requests for erasure shall be complied with, and
            the data subject informed of the erasure, within one month of
            receipt of the data subject’s request (this can be extended by up to
            two months in the case of complex requests, and in such cases the
            data subject shall be informed of the need for the extension)
          </li>
          <li>
            In the event that any personal data that is to be erased in response
            to a data subject request has been disclosed to third parties, those
            parties shall be informed of the erasure (unless it is impossible or
            would require disproportionate effort to do so)
          </li>
        </ul>
        <p>
          Restriction of Personal Data Processing: Data subjects may request
          that 300 Media ceases processing the personal data it holds about
          them. If a data subject makes such a request, we shall retain only the
          amount of personal data pertaining to that data subject that is
          necessary to ensure that no further processing of their personal data
          takes place.
        </p>
        <p>
          In the event that any affected personal data has been disclosed to
          third parties, those parties shall be informed of the applicable
          restrictions on processing it (unless it is impossible or would
          require disproportionate effort to do so).
        </p>
        <p>
          Data Portability: 300 Media processes personal data using automated
          means. Where data subjects have given their consent to 300 Media to
          process their personal data in such a manner or the processing is
          otherwise required for the performance of a contract between the
          Company and the data subject, data subjects have the legal right under
          the Regulation to receive a copy of their personal data and to use it
          for other purposes (namely transmitting it to other data controllers).
        </p>
        <p>
          To facilitate the right of data portability, 300 Media may make
          available all applicable personal data to data subjects in CSV format.
        </p>
        <p>
          Where possible and if requested by a data subject, personal data shall
          be sent directly to another data controller.
        </p>
        <p>
          All requests for copies of personal data shall be processed and
          delivered within one month of the data subject’s request. This may be
          extended by up to two months in the case of complex requests or if the
          processing is complex or there have been numerous such requests. In
          such cases the data subject shall be informed of the need for the
          extension.
        </p>
        <p>
          Objections to Personal Data Processing: Data subjects have the right
          to object to 300 Media processing their personal data.
        </p>

        <p>
          Where a data subject objects to the processing of their personal data
          based on the legitimate interests of 300 Media, we shall cease
          processing the data immediately, unless it can be demonstrated that
          the Company’s legitimate grounds for such processing override the data
          subject’s interests, rights and freedoms, or the processing relates to
          a legal claim.
        </p>

        <p>
          Where a data subject objects to 300 Media processing their personal
          data for direct marketing purposes, the Company shall cease such
          processing immediately.
        </p>
        <p>
          Automated Decision-Making In the event that 300 Media uses personal
          data for the purposes of automated decision-making and those decisions
          have a legal (or similarly significant effect) on data subjects, data
          subjects have the right to challenge such decisions under the
          Regulation, requesting human intervention, expressing their own point
          of view, and obtaining an explanation of the decision from us.
          Individuals are able to refer any concerns to us by email.
        </p>
        <span>This right does not apply in the following circumstances:</span>
        <ul>
          <li>
            The decision is necessary for the entry into, or performance of, a
            contract between the Company and the data subject
          </li>
          <li>The decision is authorised by law; or</li>
          <li>The data subject has given their explicit consent.</li>
        </ul>
        <p>
          Profiling: Where 300 Media uses personal data for profiling purposes,
          we will provide clear information explaining its use, significance and
          the likely consequences. We may utilise appropriate mathematical or
          statistical procedures i.e. algorithms, which shall be the proprietary
          ownership of 300 Media.
        </p>
        <p>
          Technical and organisational measures will be taken as necessary to
          minimise the risk of errors and to enable such errors to be easily
          corrected.
        </p>
        <p>
          Personal data processed for profiling purposes shall be secured in
          order to prevent discriminatory effects arising out of profiling.
        </p>
        <h5>Data Breach notification</h5>
        <p>
          For citizens in the UK and EU, in the event that a personal data
          breach is likely to result in a high risk to the rights and freedoms
          of data subjects, all affected data subjects will be informed by 300
          Media of the breach directly and without undue delay. Data Breaches
          will also be notified to the Information Commissioner’s Office (ICO)
          (or other relevant data protection authority) without delay, and in
          any event, within 72 hours.
        </p>
        <h5>Data Retention Periods</h5>
        <p>
          300 Media will keep records in accordance with the statutory retention
          periods.
        </p>
        <h5>Data Storage</h5>
        <p>
          Information and records relating to clients are stored securely and
          only accessible to authorised personnel. Personal data is stored only
          for as long as it is needed or required by statute and will be
          disposed of appropriately
        </p>
        <p>
          300 Media take appropriate steps to ensure that all personal and
          company data is non-recoverable from any computer system previously
          used, when sold or otherwise passed over to a third-party.
        </p>
        <p>
          Where special categories of personal data are processed, extra
          measures will be taken to ensure that information disclosed relating
          to individuals is handled appropriately.
        </p>
        <h5>Transferring personal data</h5>
        <span>
          300 Media may transfer, including making available remotely, personal
          data to countries outside of the EEA. The transfer shall take place
          only if one or more of the following applies:
        </span>
        <ul>
          <li>
            The transfer is to a country, territory, or one or more specific
            sectors in that country, that the European Commission has determined
            ensures an adequate level of protection for personal data
          </li>
          <li>
            The transfer is to a country, or international organisation, which
            provides appropriate safeguards as approved by the Information
            Commissioner’s Office; or as permitted by the GDPR
          </li>
          <li>
            The transfer is made with the informed consent of the relevant data
            subject(s)
          </li>
          <li>
            The transfer is necessary for the performance of a contract between
            the data subject and the Company (or for pre-contractual steps taken
            at the request of the data subject)
          </li>
          <li>
            The transfer is necessary for important public interest reasons
          </li>
          <li>The transfer is necessary for the conduct of legal claims</li>
          <li>
            The transfer is necessary to protect the vital interests of the data
            subject or other individuals where the data subject is physically or
            legally unable to give their consent; or
          </li>
          <li>
            The transfer is made from a register that, under UK or EU law, is
            intended to provide information to the public and which is open for
            access by the public in general or otherwise to those who are able
            to show a legitimate interest in accessing the register.
          </li>
        </ul>
        <h5>Organisational Measures</h5>
        <p>
          All employees and those working in association with 300 Media shall be
          made aware of their individual responsibilities and the Company’s
          responsibilities under this Policy. Only employees or other parties
          working on our behalf that need access to, and use of, personal data
          in order to carry out their assigned duties correctly shall have
          access to personal data held by 300 Media.
        </p>
        <h5>Data Processors</h5>
        <p>
          Third party Data Processors may be used to provide elements of our
          service. We will ensure that we have contracts in place with our data
          processors which are legally binding to ensure that personal data is
          not disclosed to unauthorised parties, that it is held securely and
          only used on the lawful basis for which it is collected. Data
          processors are required to keep records of all data processing
          activities.
        </p>
        <p>
          Data Processors are obliged to assist and cooperate with Data
          Controllers to fulfil their obligations under the GDPR. Where
          appropriate third-parties shall be required to sign a non-disclosure
          agreement.
        </p>
        <h5>Marketing</h5>
        <p>
          Where personal data held is used for marketing purposes, we will
          ensure that prior to use, details are checked against any marketing
          preference databases including, the Telephone Preference Service, the
          Mail Preference Service, the Email Preference Service, and the Fax
          Preference Service, in the relevant country. Further details in
          respect to privacy are explained in our Privacy Policy available on
          the website.
        </p>
        <h5>Record Marketing</h5>
        <p>
          300 Media shall maintain appropriate records in order to administer
          this Data Protection Policy. These will be maintained in an electronic
          format on the company’s record-keeping system.
        </p>
        <h5>Policy Review</h5>
        <p>
          This Data Protection Policy will be reviewed and monitored regularly
          to ensure that it is effective, relevant and up-to-date with best
          practice and data protection law.
        </p>
      </div>
    </section>
  );
}

export default GDPR;
