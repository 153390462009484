import React from "react";
import { NavLink } from "react-router-dom";
import {
  Row,
  Col,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import "./Services.css";

// Image Imports

import seo_header from "../../../images/services/seo/seo_header.svg";
import local_seo from "../../../images/services/seo/local_seo.svg";
import onpage_seo from "../../../images/services/seo/onpage_seo.svg";
import offpage_seo from "../../../images/services/seo/offpage_seo.svg";
import tech_seo from "../../../images/services/seo/tech_seo.svg";
import top_keywork from "../../../images/services/seo/top_keywork.svg";
import organic_traffic from "../../../images/services/seo/organic_traffic.svg";
import optimized_conversion from "../../../images/services/seo/optimized_conversion.svg";
import ranking_graph from "../../../images/services/seo/ranking_graph.svg";
import usp_img from "../../../images/services/seo/usp_img.svg";
import keyword_icon from "../../../images/services/seo/keyword_icon.svg";
import competition_icon from "../../../images/services/seo/competition_icon.svg";
import optimization_icon from "../../../images/services/seo/optimization_icon.svg";

// End of Image Imports

const faqSection1 = [
  {
    id: 1,
    question: "What are SEO Services?",
    answer: `SEO services are dedicated to optimizing websites and improving their search engine rankings, ultimately increasing visibility. These services focus on driving quality traffic to websites by enhancing their online presence.`,
  },
  {
    id: 2,
    question: "Can you explain SEO in simple words?",
    answer: `Search Engine Optimization (SEO) involves techniques to enhance website visibility and improve its ranking on search engine results pages. It involves strategies like keyword optimisation, creating quality content, on-page and off-page optimisation, and links. The primary goal of SEO is to increase website visibility and attract relevant traffic from search engines. One of the primary goals of any business is to attract online visitors to their website. With increased website traffic comes the potential for increased sales and revenue.`,
  },
  {
    id: 3,
    question: "Does your SEO service offer any performance guarantee?",
    answer: `While we cannot provide explicit guarantees for search engine rankings through SEO, we assure you that we implement ethical best practices. Our experienced team keeps up with the dynamic nature of search engine algorithms and ensures the usage of proven strategies to help you achieve sustainable ranking improvements. Our past successes and case studies validate our ability to deliver effective results.`,
  },
  {
    id: 4,
    question:
      "After implementing SEO, how long will it take for my website to rank on the first page of search results?",
    answer: `The time taken to achieve desired rankings through SEO is not guaranteed, as it depends on various factors. Search engines consider over 200 ranking factors, and achieving results can be challenging, especially for competitive keywords. However, based on our experience, we have seen websites achieve rankings for long-tail keywords within three months and for competitive keywords within six months.`,
  },
  {
    id: 5,
    question: "How do you ensure SEO through link-building campaigns?",
    answer: `Our link-building strategy involves creating compelling content that resonates with influencers, bloggers, digital PR platforms, and publishers. By promoting relevant content and building quality backlinks, we aim to improve the website's authority and search engine rankings. Our influencer outreach campaigns play a significant role in achieving effective link-building outcomes.`,
  },
];

const faqSection2 = [
  {
    id: 6,
    question: "What is your process for conducting research on relevant keywords for SEO?",
    answer: `Our team utilises a combination of automated tools and manual research to identify the most appropriate keywords for your search. We analyse search volume, competition, relevancy, and conversion potential to devise a list of target keywords for your SEO strategy.`,
  },
  {
    id: 7,
    question: "How would you conduct a technical SEO audit of my website?",
    answer: `Our technical SEO audit involves a thorough review of your website's architecture, code, and on-page elements. We analyse the underlying potential by assessing if your web pages include essential elements for optimisation. Additionally, our off-page SEO review examines your brand's online footprint to ensure an optimal experience for users and search engine crawlers. Our SEO audit services offer comprehensive insights for better search engine performance.`,
  },
  {
    id: 8,
    question: "Will you optimise my website only for specific geographics?",
    answer: `No, our team will optimise your website to increase visits from all relevant and targeted geographies. Geographic targeting is a crucial aspect of SEO, helping your website attract relevant visitors from various locations.`,
  },
  {
    id: 9,
    question:
      "What should be my budget for SEO?",
    answer: `The investment for an SEO campaign depends on various factors, such as the number and quality of keywords being targeted, the age of your website, and the competitiveness of your industry. As quality SEO requires a customised approach, We suggest scheduling a call with our team to discuss your specific requirements and get a suitable quote.`,
  },
  {
    id: 10,
    question: "How will SEO help my business?",
    answer: `SEO can benefit your business in multiple ways, including improving your website's visibility on search engine results pages (SERPs), driving more high-quality organic traffic, establishing brand credibility and authority, and enhancing the overall user experience of your website. With higher rankings, you can attract relevant customers, generate leads, and increase sales.`,
  },
  {
    id: 11,
    question: "Do you perform E-commerce SEO?",
    answer: `Yes, as a reputable SEO company specializing in various industries, including e-commerce, we offer E-commerce SEO services. Our dedicated E-commerce SEO focuses on optimizing online stores and product-specific pages, ensuring their visibility on search engines. We aim to improve search engine rankings, drive targeted traffic to your website, and boost online sales.`,
  },
];

function Seo() {
  return (
    <section className="seo-section-wrap">
      <div className="container">
        <div className="seosec-header">
          <Row>
            <Col
              sm="12"
              lg="6"
              md="6"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="ssec-head-content">
                <h3>
                  <span>SEO</span> Services
                </h3>
                <p>
                  300 Media is one of the leading SEO companies that have been
                  providing top-notch performance-oriented services.
                </p>
                <NavLink to="/contact">
                  <button className="sseo-btn">Contact Us</button>
                </NavLink>
              </div>
            </Col>
            <Col sm="12" lg="6" md="6">
              <div className="sseo-head-img">
                <img src={seo_header} alt="SEO" />
              </div>
            </Col>
          </Row>
        </div>
        {/* Our Exclusive Services */}
        <div className="sseo-exclusive-serv-wrap">
          <h4>Our Exclusive Services</h4>
          <div className="sseo-exc-item-wrap">
            <div className="sseo-exc-item">
              <Row>
                <Col
                  sm="12"
                  lg="5"
                  md="5"
                  className="d-flex align-items-center"
                >
                  <div className="sseo-exc-img">
                    <img src={local_seo} alt="Local SEO" />
                  </div>
                </Col>
                <Col sm="12" lg="7" md="7">
                  <div className="sseo-exc-text">
                    <h5>Local SEO</h5>
                    <p>
                      Local SEO is a process of enhancing the local search
                      visibility of small-scale and medium-level enterprises
                      within the geographical zone. Lists of local SEO ranking
                      factors are GMB listing, NAP consistency, domain
                      authority, CTR, optimisation of keywords, integrated
                      signals, User Experience, etc. Local Search Engine
                      Optimization is the most crucial one for a brand’s
                      success. To process local SEO, the SEO analysts have to
                      target the SEO keywords, local search citations, link
                      building, On-page SEO, content marketing, and more. 300
                      Media is a leading local SEO company located in Manchester
                      providing inclusive local SEO services to several
                      enterprises globally.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sseo-exc-item">
              <Row>
                <Col sm="12" lg="7" md="7">
                  <div className="sseo-exc-text">
                    <h5>On-page SEO</h5>
                    <p>
                      On-page SEO is the most essential for the success of SEO.
                      The webmaster optimises web pages, Metadata and HTML. Most
                      SEO organizations generate web traffic, process
                      SEO-friendly websites, and increase site ranking by using
                      on-page elements. These elements mainly focus on the Meta
                      description, On-page images, videos, creation of
                      hyperlinks, schema structure, etc. Our unique On-page SEO
                      services include quality blog posts, keyword research, SEO
                      audit, analytics tracking, issue remediation, and more.
                      300 Media's On-page SEO services are 100% customizable to
                      satisfy our client’s needs and demands. Day by day, new
                      technologies are arising and hence we are introducing
                      contemporary techniques, real-time concepts, and exclusive
                      search result ranking factors.
                    </p>
                  </div>
                </Col>
                <Col
                  sm="12"
                  lg="5"
                  md="5"
                  className="d-flex align-items-center justify-content-end"
                >
                  <div className="sseo-exc-img">
                    <img src={onpage_seo} alt="On-page SEO" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sseo-exc-item">
              <Row>
                <Col
                  sm="12"
                  lg="5"
                  md="5"
                  className="d-flex align-items-center"
                >
                  <div className="sseo-exc-img">
                    <img src={offpage_seo} alt="Off-page SEO" />
                  </div>
                </Col>
                <Col sm="12" lg="7" md="7">
                  <div className="sseo-exc-text">
                    <h5>Off-page SEO</h5>
                    <p>
                      Off-page SEO is essential to enhance a website’s ranking
                      and visibility. Our experienced team conducts SEO audits,
                      monitors the backlink profile, and develops brands on
                      powerful websites. We build and execute off-page
                      formulated SEO strategies successfully. Our professionals
                      possess relevant experience in terms of digital marketing
                      and are also Google certified. We mainly target ethical
                      link building, provide quality work and develop
                      power-packed local SEO for our prospective clients. 300
                      Media builds a healthy relationship with our local and
                      global customers & enhances the web authority.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sseo-exc-item">
              <Row>
                <Col sm="12" lg="7" md="7">
                  <div className="sseo-exc-text">
                    <h5>Technical SEO</h5>
                    <p>
                      The Technical SEO services are meant to enhance the
                      performance of the website and upgrade user experience. By
                      ranking the website as a top-notch place, more quality is
                      guaranteed. Our specialists are aware of all the SEO ropes
                      and hence we do not fail to use our knowledge to satisfy
                      your SEO needs. We cover a huge range of activities that
                      are subjective with the eradication of common issues. Once
                      the SEO audit is completed successfully, our experts are
                      involved in the optimisation of indexation. We provide
                      top-rated SEO results, enhanced user experience, upgrades
                      on Conversion Rate, and optimised efficiency on
                      Smartphones.
                    </p>
                  </div>
                </Col>
                <Col
                  sm="12"
                  lg="5"
                  md="5"
                  className="d-flex align-items-center justify-content-end"
                >
                  <div className="sseo-exc-img">
                    <img src={tech_seo} alt="Tech SEO" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {/* Why Hire Us */}
      <div className="sseo-why-hire-us">
        <div className="container">
          <h3>
            <span>Why</span> hire us?
          </h3>
          <h6>
            At 300 Media, we possess authorised access to a set of technological
            tools. Our experts work hard to provide efficient SEO campaigns.
            Whatever may be the requirements, targets, or demands, we are
            readily available to accomplish them. We assure you that your brand
            will reach visibility in the search engine in no time. It
            automatically leads to enhanced sales and more profit.
          </h6>
          <div className="sseo-whu-item-wrap">
            <Row>
              <Col sm="12" lg="4" md="6">
                <div className="sseo-whu-item">
                  <img src={top_keywork} alt="Keyword" />
                  <h4>Top-rated Keyword Rankings</h4>
                  <p>
                    As the leading SEO Company, we help your brand to achieve
                    top ranking for your set of focused keywords. Our team of
                    experts researches optimised keywords and performs
                    competitive niche.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="4" md="6">
                <div className="sseo-whu-item">
                  <img src={organic_traffic} alt="Keyword" />
                  <h4>Enhanced Organic Traffic</h4>
                  <p>
                    Along with our highly-privileged SEO measures, we ensure
                    100% organic traffic. We provide cutting-edge and efficient
                    solutions impeccably. Our SEO analysts utilise the latest
                    strategies to acquire 100% traffic from technical SEO.
                  </p>
                </div>
              </Col>
              <Col sm="12" lg="4" md="6">
                <div className="sseo-whu-item">
                  <img src={optimized_conversion} alt="Keyword" />
                  <h4>Optimised Conversion Rate</h4>
                  <p>
                    You can always trust us!! We lead only organic and quality
                    traffic toward your authoritative website. 300 Media serve
                    global-based clients and support them with upgraded
                    conversion rate integrated with White Hat SEO techniques.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* Service Aid */}
      <div className="high-service-aid-wrap">
        <div className="container">
          <div className="hsa-img">
            <img src={ranking_graph} alt="Graph" />
          </div>
          <h3>
            Our highly privileged services aid in{" "}
            <span>Web Traffic &amp; Rankings</span>
          </h3>
          <p>
            We produce reliable and tailor-made solutions for all your demands.
          </p>
          <NavLink to="/contact">
            <button className="sseo-btn">Contact Us</button>
          </NavLink>
        </div>
      </div>
      {/* SEO USP Points */}
      <div className="seo-usp-wrap">
        <div className="container">
          <h3>
            Our team of professionals affords top-quality services,{" "}
            <span>100% Web Traffic and prime-oriented rankings</span>
          </h3>
          <div className="seo-usp-points">
            <Row>
              <Col
                sm="12"
                lg="8"
                md="12"
                className="d-flex align-items-center justify-content-center"
              >
                <div className="seousp-img">
                  <img src={usp_img} alt="USP" />
                </div>
              </Col>
              <Col sm="12" lg="4" md="6">
                <div className="seousp-points-wrap">
                  <div className="seousp-points-item">
                    <img src={keyword_icon} alt="Keyword" />
                    <h4>Keyword Research</h4>
                    <p>
                      Our SEO Analysts select the right keywords that
                      automatically direct toward top rankings. After selecting
                      the keywords, we move on to the completely data-driven
                      design step.
                    </p>
                  </div>
                  <div className="seousp-points-item">
                    <img src={competition_icon} alt="Competition" />
                    <h4>Competition Analysis</h4>
                    <p>
                      Our unique optimisation process leads to the rapid loading
                      time of the website and top rankings on search results. We
                      optimise your website wisely to generate 100% traffic and
                      increase visibility.
                    </p>
                  </div>
                  <div className="seousp-points-item">
                    <img src={optimization_icon} alt="Optimization" />
                    <h4>Website Optimization</h4>
                    <p>
                      Our team uses modernised tools to spot the issues, and we
                      fix them instantly. Our techies implement technical SEO
                      methodologies with ease.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* FAQ */}
      <div className="seo-faq-wrap">
        <div className="container">
          <h3>Frequently Asked Questions</h3>
          <div className="seo-faq-item-wrap">
            <Row>
              <Col sm="12" lg="6" md="6">
                <UncontrolledAccordion id="seo_faq" defaultOpen={1}>
                  {faqSection1.map((item) => {
                    return (
                      <AccordionItem>
                        <AccordionHeader targetId={item.id}>
                          {item.question}
                        </AccordionHeader>
                        <AccordionBody accordionId={item.id}>
                          <h6>{item.answer}</h6>
                        </AccordionBody>
                      </AccordionItem>
                    );
                  })}
                </UncontrolledAccordion>
              </Col>
              <Col sm="12" lg="6" md="6">
                <UncontrolledAccordion id="seo_faq">
                  {faqSection2.map((item) => {
                    return (
                      <AccordionItem>
                        <AccordionHeader targetId={item.id}>
                          {item.question}
                        </AccordionHeader>
                        <AccordionBody accordionId={item.id}>
                          <h6>{item.answer}</h6>
                        </AccordionBody>
                      </AccordionItem>
                    );
                  })}
                </UncontrolledAccordion>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Seo;
