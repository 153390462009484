import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Typed from "react-typed";

import "./Home.css";
import CallToAction from "../Common/CallToAction";
import Testimonials from "../Common/Testimonials";

// Image Imports

import HomeBg from "../../../images/HomeBg.svg";
import HomeBg3 from "../../../images/HomeBg3.svg";
import HomeBg4 from "../../../images/HomeBg4.svg";
import HomeBg5 from "../../../images/HomeBg5.svg";
import HomeBg6 from "../../../images/HomeBg6.png";
import HomeBgg from "../../../images/HomeBgg.svg";
import RightArrowLight from "../../../images/RightArrowLight.svg";
import RightArrowDark from "../../../images/RightArrowDark.svg";
import Web from "../../../images/services/Web.svg";
import App from "../../../images/services/App.svg";
import App2 from "../../../images/services/App2.svg";
import Design from "../../../images/services/Design.svg";
import LogoDesign from "../../../images/services/LogoDesign.svg";
import DigMar from "../../../images/services/DigMar.svg";
import Budget from "../../../images/usp/Budget.svg";
import Budget1 from "../../../images/usp/Budget1.svg";
import Client from "../../../images/usp/Client.svg";
import Team from "../../../images/usp/Team.svg";
import Testing from "../../../images/usp/Testing.svg";
import SmallTeam from "../../../images/usp/SmallTeam.svg";
import Adaptation from "../../../images/usp/Adaptation.svg";
import Time from "../../../images/usp/Time.svg";
import Expertise from "../../../images/usp/Expertise.svg";
import stats from "../../../images/stats.svg";
import Image1 from "../../../images/clientlogo/1.png";
import Image2 from "../../../images/clientlogo/2.png";
import Image3 from "../../../images/clientlogo/3.png";
import Image4 from "../../../images/clientlogo/4.png";
import Image5 from "../../../images/clientlogo/5.png";
import Image6 from "../../../images/clientlogo/6.png";
import Image7 from "../../../images/clientlogo/7.png";
import Image8 from "../../../images/clientlogo/8.png";
import Image9 from "../../../images/clientlogo/9.png";
import Image10 from "../../../images/clientlogo/10.png";
import Image11 from "../../../images/clientlogo/11.png";
import MediaLogo from "../../../images/business/300media.png";
import Godigi from "../../../images/business/godigital.png";
import Outset from "../../../images/business/outset.png";
import kg from "../../../images/business/kg.png";
import Cam from "../../../images/business/cam.png";
import famili_cloud from "../../../images/business/famili_cloud.jpeg";
import escape_debt from "../../../images/business/escape_debt.png";
import cloudoc from "../../../images/business/cloudoc.png";
import agency_portal from "../../../images/business/agency_portal.png";

// End of Image Imports

// Services

const services = [
  {
    name: "Web Development",
    description:
      "Considering the evolving web technologies in the technological world, we have been continuously shaping our services to meet the growing demand for fast, secure and interactive websites. We provide complete front-end and back-end development based on the latest technologies, industry trends and as per your business requirements.",
    points: [
      "Responsive Designs Out-of-the-box",
      "Best-in-class UI/UX",
      "MEAN & MERN Stack Projects",
      "Search Engine Optimization",
    ],
    imageURL: Web,
    invert: false,
  },
  {
    name: "Mobile App Development",
    description:
      "We use the latest technologies and tools to develop world-class, quality mobile apps for your business solutions. This will help you manage and run your business and access critical business information. Minimise your costs and maximise your productivity with 300 Media.",
    points: [
      "Modern Design",
      "Many Variations",
      "Suitable for all backgrounds",
      "Eye-catching brand colour selection",
    ],
    imageURL: App2,
    invert: true,
  },
  {
    name: "Web & Graphic Design",
    description:
      "We have an excellent and experienced graphic design team that can transfer your ideas from brain to screen. Your dream designs are a step away!",
    points: [
      "Social Media Optimization",
      "Lead Generation & Funnel Creation",
      "Proven Methodologies",
      "Curated Audience",
    ],
    imageURL: LogoDesign,
    invert: false,
  },
];

// USP

const usp = [
  {
    name: "Budget Friendly",
    description:
      "We offer high-quality software platforms that will easily fit within your budget. We never compromise on quality, and we ensure everything delivered is cost-effective.",
    imageURL: Budget,
  },
  {
    name: "Client-centric Approach",
    description:
      "We always focus on clients starting from the ground up, spending quality time understanding their requirements, thought processes, and expected outcomes. We focus on these core processes to achieve the desired results and have established a trusted and professional relationship through doing so.",
    imageURL: Client,
  },
  {
    name: "Capable Team",
    description:
      "We have a well-qualified and experienced team who can easily quickly provide solutions to your business needs and deliver them on time.",
    imageURL: Team,
  },
  {
    name: "On-Time Delivery",
    description:
      "We always plan your projects accordingly to ensure timely delivery, guaranteeing your success. With the right team, tools, and know-how, we will get your project up and running within no time.",
    imageURL: Time,
  },
  {
    name: "Productive Small Teams",
    description:
      "It’s common practice in companies to have a large team working on many projects. At 300 Media, we keep our teams small, agile, and focussed on you. Your project will benefit from a small business team with a sharp focus on design and development, and expert professionals.",
    imageURL: SmallTeam,
  },
  {
    name: "The Right Expertise",
    description:
      "Having the right people at the right time is very important. We have an excellent combination of highly qualified business and development professionals to effectively bring your projects to life.",
    imageURL: Expertise,
  },
  {
    name: "Quality Testing",
    description:
      "We test our websites and mobile apps thoroughly across various devices, ensuring the deliverables are bug-free and meet your expectations.",
    imageURL: Testing,
  },
  {
    name: "Quick Adaptation",
    description:
      "We will be with you through your project from day one. We conduct proper market research and planning before building your dream application. Furthermore, we gather feedback from clients and their customers to enhance the overall efficiency of our products.",
    imageURL: Adaptation,
  },
];

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "300Media | Home";
  }, []);

  return (
    <>
      {/* Main */}
      <div className="main-container">
        <main className="container main-section">
          <Row>
            <Col sm="12" md="6" lg="6" className="d-flex align-items-center">
              <div className="content">
                <h4>Keeping ahead of the future</h4>
                {/* <h1>
                  Innovation. <br />{" "}
                  <span
                    style={{ color: "var(--green-1)", fontFamily: "inherit" }}
                  >
                    Commitment.
                  </span>{" "}
                  <br /> Excellence.
                </h1> */}
                <h1>
                  <Typed
                    strings={[
                      "Web Applications",
                      "Mobile Applications",
                      "Digital Transformation",
                    ]}
                    startDelay={200}
                    typeSpeed={60}
                    backSpeed={70}
                    backDelay={3500}
                    loop
                    smartBackspace
                  />
                </h1>
                <p>
                  The ultimate destination for Innovation in{" "}
                  <span className="highlight-u">technology</span>, for a better
                  and promising future.
                </p>
                <div className="button-group">
                  <Link to="/about">
                    <Button data-contact>
                      Know More
                      <img src={RightArrowLight} alt="Right" />
                    </Button>
                  </Link>
                  <Link to="/contact">
                    <Button data-more>
                      Contact us
                      <img src={RightArrowLight} alt="Right" />
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              sm="12"
              md="6"
              lg="6"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="image-holder">
                <img src={HomeBg6} alt="300media" />
              </div>
            </Col>
          </Row>
        </main>
      </div>
      {/* What we do */}
      <section className="about-section">
        <div className="container">
          <Row>
            <Col sm="12" md="3" lg="3">
              <h2>What Do We Do?</h2>
            </Col>
            <Col sm="12" md="9" lg="9">
              <h6>
                Do you have a dream project and don’t know where and how to
                start? We can help! We are here for all your web & software
                development, digital consulting, and transformation needs. We
                have got you covered on all your digital business needs. We are
                leaders in software development with a team of passionate
                developers and intelligent working professionals. We can build
                your dream projects by offering quality service ideas to brand
                your products and services at an unmissable cost!
              </h6>
            </Col>
          </Row>
          {/* Services */}
          <div className="services">
            <Row>
              {services.map((service) => {
                return (
                  <Col sm="12" md="4" lg="4" className="d-flex">
                    <div className="service-item">
                      <div className="service-img">
                        <img src={service.imageURL} alt={services.imageURL} />
                      </div>
                      <h3>{service.name}</h3>
                      <p>{service.description}</p>
                      {/* <ul className="tag-group">
                            {service.points.map((point) => {
                              return (
                                <li>
                                  <span className="highlight-u">{point}</span>
                                </li>
                              );
                            })}
                          </ul> */}
                      {/* <a href="/services">
                        <Button className="findmorebtn">Find out more</Button>
                      </a> */}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <Link to="/services">
            <Button className="view-services-btn">View All Services</Button>
          </Link>
        </div>
      </section>
      {/* Why Choose Us */}
      <div className="why-choose-container">
        <section className="container usp">
          <h2 className="mb-4">Why Choose Us?</h2>
          <h5>
            Our strategy is dependent on your world and your goals. Moving
            towards your goals is our dream too!
          </h5>
          <p>
            The most important aspect of building a project is choosing the
            right company to help achieve your ambition efficiently and
            professionally. We are here to ensure the correct implementation
            techniques, strategies, optimised code, and security is taken care
            of. Your dream is at the forefront of everything we do, and with
            immaculate practices, tools, and processes, we look forward to
            building your vision.
          </p>
          {/* USP Points */}
          <div className="usp-points">
            <Row>
              {usp.map((item) => {
                return (
                  <Col sm="12" md="6" lg="3">
                    <div className="usp-item">
                      <div className="usp-content">
                        <div className="usp-img">
                          <img src={item.imageURL} alt={item.imageURL} />
                        </div>
                        <h4>{item.name}</h4>
                      </div>
                      <p>{item.description}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </section>
      </div>
      {/* Contact CTA */}
      <section className="contact-cta">
        <div className="container ccta-bg">
          <Row>
            <Col sm="12" lg="7" md="7">
              <h3>
                Need a start? We can take your business to a whole new level.
              </h3>
              <p>
                We are here to help you grow your business exponentially,
                helping you relax and benefit from excellent results.
              </p>
              <a href="/contact">
                <Button className="contact-us-cta">
                  Contact us
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </Button>
              </a>
            </Col>
          </Row>
        </div>
      </section>
      <section className="our-stats">
        <div className="ourstats-container container">
          <h2>Some of our clients in our arsenal</h2>
          <p>
            We have a lot of elite clients that we’ve worked with in our
            journey.
          </p>
          <p>
            Despite operating for a short amount of time, we have managed to
            earn great respect from our customers. We are more than grateful
            that they’re happy to work with us. Meet with us for a cup of
            coffee, and we can discuss how we can breathe life into your vision.
          </p>
          <div className="slider-wrap">
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              autoplayTimeout="3000"
              navText={false}
              dots={false}
              autoplay
              autoplayHoverPause
              responsive={{
                0: {
                  items: 1,
                  nav: true,
                },
                600: {
                  items: 3,
                  nav: false,
                },
                1000: {
                  items: 4,
                  nav: true,
                },
              }}
            >
              <div class="item">
                <img src={Cam} alt="CAM" />
              </div>
              <div class="item">
                <img src={kg} alt="Kingdom Granites" />
              </div>
              <div class="item">
                <img src={escape_debt} alt="Escape Debt" />
              </div>
              <div class="item">
                <img
                  src={famili_cloud}
                  alt="Famili Cloud"
                  style={{ width: "120px" }}
                />
              </div>
              <div class="item">
                <img src={cloudoc} alt="Cloudoc" />
              </div>
              <div class="item">
                <img src={agency_portal} alt="Agency Portal" />
              </div>
            </OwlCarousel>
          </div>
          {/* <a href="/projects">
            <Button id="projectsBtn">
              View our projects
              <img src={RightArrowLight} alt="Right" />
            </Button>
          </a> */}
          {/* <div className="divider"></div> */}
          {/* <div className="our-business">
            <h2>Our Businesses</h2>
            <div className="logo-group">
              <div className="logo-item">
                <img src={Cam} alt="CAM" />
              </div>
              <div className="logo-item">
                <img src={kg} alt="Kingdom Granites" />
              </div>
              <div className="logo-item">
                <img src={escape_debt} alt="Escape Debt" />
              </div>
              <div className="logo-item">
                <img
                  src={famili_cloud}
                  alt="Famili Cloud"
                  style={{ width: "120px" }}
                />
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <Testimonials />
      <CallToAction />
    </>
  );
}

export default Home;
