import React from "react";
import { Row, Col } from "reactstrap";

// Image Imports

import TestimonialsWave from "../../../images/TestimonialsWave.svg";
import maleUser from "../../../images/testimonial/man.png";
import femaleUser from "../../../images/testimonial/woman.png";

// End of Image Imports

// Testimonials

const testimonials = [
  {
    name: "Arthur Freddie",
    desg: "Director, Care Agency Media",
    testimonial:
      "I would like to express my satisfaction and happiness regarding the development of our web application. 300 Media and their development team did a very professional job. We are satisfied with the solution given to us and with the support through the project. I would like to recommend 300 Media. We look forward to working with them in future projects.",
    photo: "",
    female: false,
  },
  {
    name: "Victor M",
    desg: "Managing Director, Kingdom Granites",
    testimonial:
      "300 Media has been supporting our business for the past 6 months in development and implementation of new business solution. We have worked with them and cannot speak highly enough of the team. They are reliable, thorough, smart, available, extremely good communicators and very friendly! We would recommend hiring 300 Media to anyone looking for a highly productive and solution driven team. We plan to continue to work with them for the long term.",
    photo: "",
    female: false,
  },
  {
    name: "Johan",
    desg: "General Manager, Escape Debt",
    testimonial:
      "We've been trying to put together a functional website since I began my business in 2021. I am happy to say we finally hired the 300 Media team and they've worked closely with us throughout the process, staying on task, on target, and on budget. I also appreciate their quick and courteous responses. I highly recommend their service!",
    photo: "",
    female: false,
  },
  {
    name: "Archie Sharpe",
    desg: "Director, Famili Cloud",
    testimonial:
      "They know what they are doing, they do their best, and they are all super friendly, reliable, communicative people.",
    photo: "",
    female: false,
  },
];

function Testimonials() {
  return (
    <section className="testimonial-section">
      <div className="testimonial-content container">
        <span className="tag">Our wonderful customers</span>
        <h1>What our customers say about us</h1>
        <p className="mt-2">
          In the short time our company has been operating, we have managed to
          win the respect and repeated custom of many of our clients. We are
          grateful for everyone who chooses to work with us, and we aim to show
          this through our attentive customer service and technical support. Why
          not give us a call today and discuss your next big project with us
          over a cup of coffee?
        </p>
        <div className="testimonial-wrap">
          <Row>
            {testimonials.map((testimonial) => {
              return (
                <Col sm="12" md="6" lg="4">
                  <div className="tml-item">
                    <div className="tml-cont">
                      <p>{testimonial.testimonial}</p>
                      <div className="d-flex align-items-center gap-3 tml-name">
                        {testimonial.female === true ? (
                          <img src={femaleUser} alt="Female User" />
                        ) : (
                          <img src={maleUser} alt="Male User" />
                        )}
                        <div>
                          <span>{testimonial.name}</span>
                          <span data-desg>{testimonial.desg}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      {/* <img
        src={TestimonialsWave}
        alt="Testimonials"
        className="testimonial_bg"
      /> */}
    </section>
  );
}

export default Testimonials;
