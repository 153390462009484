import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";

import "./AboutUs.css";
import CallToAction from "../Common/CallToAction";

// Image Imports
import About1 from "../../../images/About1.svg";
import AboutImg from "../../../images/projects/about.svg";

// End of Image Imports

import ProjectHeader from "../Common/ProjectHeader";

function AboutUs(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About Us | 300Media";
  }, []);
  return (
    <>
      <ProjectHeader title="Who We Are" img={AboutImg} />
      <section className="about-us">
        <div className="container p-0">
          <Row>
            <Col sm="12" md="7" lg="7">
              <span className="tag">Where it all began</span>
              <h2>Story of 300 Media</h2>
              <p className="text-justify">
                300 Media is a web and mobile application development company
                founded on the basis of helping brands employ the right tools,
                practices and resources to ensure they succeed in business. Over
                the years, our team has gained extensive experience in software
                development and digital transformation.
              </p>
              <p>
                We have worked with several established companies and start-ups,
                helping them to set up and grow their businesses. We understand
                the value of leaving a long-lasting impression in the world of
                technology. This has helped us establish strategic partnerships,
                pull a mass of resources as well as the expert talent to create
                a sustainable business model that ensures delivery at a high
                service standard.
              </p>
            </Col>
            <Col sm="12" md="5" lg="5" className="d-flex align-items-center">
              <div className="image-holder">
                <img src={About1} alt="About" />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <CallToAction />
    </>
  );
}

export default AboutUs;
