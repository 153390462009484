import React from "react";

import BreadCrumbs from "../Common/BreadCrumbs";

function ProjectHeader(props) {
  return (
    <div className="project-header">
      <div className="container pro-header-content">
        <BreadCrumbs title={props.title} />
        <h3>{props.title}</h3>
      </div>
      <div className="project-header-img">
        <img src={props.img} alt="Projects" width={props.size} />
      </div>
    </div>
  );
}

export default ProjectHeader;
