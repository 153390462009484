import React from "react";

import bcFor1 from "../../../images/bcFor1.svg";

function BreadCrumbs(props) {
  return (
    <section className="breadcrumbs">
      <div className="d-inline-flex align-items-center gap-2">
        <span>Home</span>
        <span>
          <img src={bcFor1} alt="Forward" width={10} id="bcFor" />
        </span>
        <span>{props.title}</span>
      </div>
    </section>
  );
}

export default BreadCrumbs;
