import React from "react";

function VideoEmbed() {
  return (
    <section className="video-embed">
      <div className="video-wrap">
        <h4>300 Media - IT &amp; System Training</h4>
        <div className="video-wrap-iframe">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/-dYE3G-zMpg"
            title="IT Services - 300Media Pvt. Ltd."
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default VideoEmbed;
