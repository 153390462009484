import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import "./Careers.css";

import ProjectHeader from "../Common/ProjectHeader";

// Image Imports

import CareersImg from "../../../images/projects/careers.svg";
import CareersBg from "../../../images/careers/careersBg.svg";
import exp from "../../../images/careers/exp.svg";
import loc from "../../../images/careers/loc.svg";
import time from "../../../images/careers/time.svg";
import down from "../../../images/careers/down.svg";
import Send from "../../../images/Send.svg";

// End of Image Imports

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Careers | 300media - Web and Mobile Applications Development";
  }, []);

  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [applyBtn, setApplyBtn] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const applyBtnToggle = () => {
    setApplyBtn(!applyBtn);
    setOpenForm(!openForm);
  };

  const cancelBtn = () => {
    setOpenForm(!openForm);
    setApplyBtn(false);
  };

  return (
    <>
      <ProjectHeader title="Careers" img={CareersImg} size={30} />
      <section className="careers-section">
        <div className="careers-header">
          <div className="container">
            <Row>
              <Col
                sm="12"
                lg="8"
                md="8"
                className="d-flex align-items-start flex-column justify-content-center"
              >
                <h1>Join our team</h1>
                <h4>
                  We are a fast growing, passionate team making a difference
                  looking to join hands with talented tech enthusiasts to help
                  us deliver our work to leading organizations around the world.
                  Come collaborate with us to build some existing projects and
                  explore the depths of the technological universe.
                </h4>
              </Col>
              <Col sm="12" lg="4" md="4">
                <img src={CareersBg} alt="Careers" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="job-lists">
          <div className="container">
            <div className="divider"></div>
            <h5>Current Openings</h5>
            <ul className="job-list">
              <li className="job-item">
                <h4 className="d-inline-flex align-items-center gap-4">
                  MERN Stack Developer (MongoDB, ExpressJS, ReactJS and NodeJS)
                  <Button
                    id="applyJobBtn"
                    className={`mt-1 ${applyBtn ? "hideBtn" : ""}`}
                    onClick={applyBtnToggle}
                  >
                    Apply
                  </Button>
                </h4>
                <div
                  className="d-flex align-items-center gap-1 view-det"
                  onClick={toggle}
                >
                  <span>{open ? "Hide" : "View"} Details</span>
                  <img
                    src={down}
                    alt="Down"
                    className={`arrowImg ${open ? "rotateArrow" : ""}`}
                    width={17}
                  />
                </div>
                <div className="tab-dets">
                  <div>
                    <img src={exp} alt="Experience" width={15} />
                    <span>0 - 2y</span>
                  </div>
                  <div>
                    <img src={time} alt="Time" width={15} />
                    <span>Full Time</span>
                  </div>
                  <div>
                    <img src={loc} alt="Location" width={15} />
                    <span>Chennai, Tamil Nadu</span>
                  </div>
                </div>
                <form
                  method="POST"
                  className={`apply-form ${openForm ? "show" : ""}`}
                >
                  <Row>
                    <Col sm="12" lg="6" md="6">
                      <div className="form-box">
                        <label htmlFor="full_name">Full Name</label>
                        <input
                          type="text"
                          id="full_name"
                          placeholder="Eg: John Doe"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6" md="6">
                      <div className="form-box">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          placeholder="Eg: johndoe@gmail.com"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6" md="6">
                      <div className="form-box">
                        <label htmlFor="contact_number">Contact Number</label>
                        <input
                          type="text"
                          id="contact_number"
                          placeholder="Eg: 1234567890"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="6" md="6">
                      <div className="form-box">
                        <label htmlFor="total_exp">Total Experience</label>
                        <input
                          type="number"
                          id="total_exp"
                          placeholder="in years"
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="12" md="12">
                      <div className="form-box">
                        <label htmlFor="prev_comp">Current Company</label>
                        <input
                          type="text"
                          id="prev_comp"
                          placeholder="Eg: TechnoSoft Inc."
                        />
                      </div>
                    </Col>
                    <Col sm="12" lg="12" md="12">
                      <div className="form-box">
                        <label htmlFor="upload_resume">
                          Upload your Updated Resume
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="upload_resume"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      id="applyJobBtn"
                      style={{
                        background: "var(--black)",
                        color: "var(--white)",
                      }}
                    >
                      Send your application
                      <img src={Send} alt="Send" width="16" />
                    </Button>
                    <Button
                      id="applyJobBtn"
                      style={{
                        background: "var(--bs-danger)",
                        color: "var(--white)",
                      }}
                      onClick={cancelBtn}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
                <div className={`job-details ${open ? "open" : ""}`}>
                  <Row className="mt-3">
                    <Col sm="12" lg="6" md="6">
                      <h6>Roles and Responsibilities</h6>
                      <ul className="job-inner-list">
                        <li>Developing front end website architecture.</li>
                        <li>Designing user interactions on web pages.</li>
                        <li>Developing back-end website applications.</li>
                        <li>
                          Creating servers and databases for functionality.
                        </li>
                        <li>
                          Ensuring cross-platform optimisation for mobile
                          phones.
                        </li>
                        <li>Ensuring responsiveness of applications.</li>
                        <li>
                          Working alongside graphic designers for web design
                          features.
                        </li>
                        <li>
                          Seeing through a project from conception to finished
                          product.
                        </li>
                        <li>Designing and write effective APIs.</li>
                        <li>Meeting both technical and consumer needs.</li>
                        <li>
                          Staying abreast of developments in web applications
                          and programming languages.
                        </li>
                        <li>
                          Work with development teams and product managers to
                          ideate software solutions.
                        </li>
                      </ul>
                    </Col>
                    <Col sm="12" lg="6" md="6">
                      <h6>Desired Candidate Requirements</h6>
                      <ul className="job-inner-list">
                        <li>
                          Degree in Computer Science or BE/B.Tech or any other
                          related streams.
                        </li>
                        <li>
                          Strong organisational and project management skills.
                        </li>
                        <li>
                          Proficiency with fundamental front-end languages such
                          as HTML, CSS, and JavaScript.
                        </li>
                        <li>
                          Familiarity with JavaScript frameworks such as React
                          JS.
                        </li>
                        <li>
                          Proficiency with server-side languages such as Node
                          JS.
                        </li>
                        <li>
                          Familiarity with database technology such as MySQL
                          MongoDB.
                        </li>
                        <li>Excellent verbal communication skills.</li>
                        <li>Good problem-solving skills.</li>
                        <li>Attention to detail.</li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;
