import React, { useEffect, useState, useRef } from "react";
import emailjs from "emailjs-com";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import toast from "react-hot-toast";
import moment from "moment";

import CallToAction from "../Common/CallToAction";
import "./Contact.css";

// Image Imports

import Send from "../../../images/Send.svg";
import BtnLoadingDark from "../../../images/BtnLoadingDark.svg";
import Phone from "../../../images/Phone.svg";
import Email from "../../../images/Email.svg";
import Address from "../../../images/Address.svg";
import WhatsApp from "../../../images/WhatsApp.svg";
import ContactImg from "../../../images/projects/contact.svg";
import refresh from "../../../images/refresh.svg";

// End of Image Imports

import ProjectHeader from "../Common/ProjectHeader";

const initialValues = {
  full_name: "",
  phone_number: "",
  email: "",
  message: "",
  captcha_text: "",
};

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact Us | 300Media";
  }, []);

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);

  const { full_name, phone_number, email, message, captcha_text } = values;

  const loadCaptcha = () => loadCaptchaEnginge(6, "white", "black", "lower");
  useEffect(loadCaptcha, []);

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();

    setValues({ ...values, [name]: value });
  };

  const onChangeNumber = (e, type) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      // Allow only numbers in textbox
      var num = String(e.target.value);
      setValues({
        ...values,
        [e.target.name]: num,
      });
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const form = useRef();

  const submitHandler = (e) => {
    e.preventDefault();
    if (full_name === "") {
      return toast.error("Please let us know your name");
    }
    if (phone_number === "") {
      return toast.error("Please give us your contact number");
    }
    if (email === "") {
      return toast.error("Please give us your email", {
        id: "email",
      });
    } else if (!validateEmail(email)) {
      return toast.error("Seems like an invalid email", {
        id: "email",
      });
    }
    if (message === "") {
      return toast.error("Please type in your message");
    }
    const isCaptchaMatched = validateCaptcha(captcha_text, false);
    console.log(captcha_text);
    if (captcha_text === "") {
      return toast.error("Enter Captcha");
    } else if (!isCaptchaMatched) {
      toast.error("Captcha not matched");
      return;
    }
    // const data = new FormData();
    // data.append("Name", full_name);
    // data.append("Contact", phone_number);
    // data.append("Email", email);
    // data.append("Message", message);
    // data.append("Timestamp", moment().format("DD-MM-YYYY hh:mm a"));

    setLoading(true);
    emailjs
      .sendForm(
        "service_la8vybd",
        "template_p85iuqm",
        form.current,
        "Yhc7t1VPS953ccXYS"
      )
      .then(
        () => {
          setLoading(false);
          toast.success("Submitted Successfully!");
        },
        (error) => {
          toast.error("Sorry, try again!", error);
        }
      );
    setValues(initialValues);
  };

  // Modal window
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <ProjectHeader title="Get in touch" img={ContactImg} />
      <section className="contact-us">
        <div className="container">
          <span className="tag">Let's make a difference together</span>
          <div className="text-btn-wrap">
            <h2>We'd love to hear from you!</h2>
            <a
              href="https://cp.300media.co.uk/basket-summary-login?r=%2Fmanage"
              rel="noopener noreferrer"
              target="_blank"
              className="cpanel-btn"
            >
              <button>
                <ion-icon name="log-in"></ion-icon>
                cPanel and Support
              </button>
            </a>
          </div>
          <Row>
            <Col sm="12" md="12" lg="4">
              <div className="contact-form">
                <form
                  ref={form}
                  method="POST"
                  name="contact_responses"
                  onSubmit={submitHandler}
                >
                  <div className="form-box">
                    <input
                      type="text"
                      name="full_name"
                      className="form-control"
                      placeholder="Full Name"
                      value={full_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-box">
                    <input
                      type="text"
                      name="phone_number"
                      className="form-control"
                      placeholder="Contact Number"
                      value={phone_number}
                      onChange={onChangeNumber}
                      maxlength="15"
                    />
                  </div>
                  <div className="form-box">
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={handleChange}
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="form-box">
                    <textarea
                      name="message"
                      id="msg_textarea"
                      className="form-control"
                      value={message}
                      onChange={handleChange}
                      placeholder="Your Message to us"
                      rows="7"
                    ></textarea>
                  </div>
                  <div className="form-box">
                    <div className="d-flex align-items-center gap-3">
                      <LoadCanvasTemplateNoReload />
                      <img
                        src={refresh}
                        alt="Refresh"
                        width={20}
                        onClick={() => loadCaptcha()}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="mt-3">
                      <span>Please enter the CAPTCHA below</span>
                      <input
                        type="text"
                        id="captcha_input"
                        name="captcha_text"
                        value={captcha_text}
                        className="form-control"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <Button
                    className="contact-us-btn mt-2"
                    name="submit"
                    type="submit"
                    onClick={submitHandler}
                  >
                    {loading ? "Sending..." : "Send us"}
                    <img
                      src={`${loading ? BtnLoadingDark : Send}`}
                      className={`${loading ? "rotate_load" : ""}`}
                      alt="Send"
                    />
                  </Button>
                </form>
              </div>
            </Col>
            <Col sm="12" md="12" lg="8">
              <div className="contact-info-wrap">
                <div className="contact-info">
                  <div className="con-item">
                    <img src={Phone} alt="Phone" />
                    <div className="con-det">
                      <h5>Call us</h5>
                      <h6>
                        <a href="tel:03308181640">0330 818 1640</a>
                      </h6>
                    </div>
                  </div>
                  <div className="con-item">
                    <img src={Email} alt="Email" />
                    <div className="con-det">
                      <h5>
                        For any clarifications, suggestions or feedback, contact
                        us @
                      </h5>
                      <h6>
                        <a href="mailto:hello@300media.co.uk">
                          hello@300media.co.uk
                        </a>
                      </h6>
                    </div>
                  </div>
                  <div className="con-item">
                    <img src={Address} alt="Address" />
                    <div className="con-det">
                      <h5>Locate us</h5>
                      <h6>
                        Unit 5, Christie Fields Office Park, <br />
                        Christie Way, West Didsbury, <br />
                        Manchester, M21 7QY
                      </h6>
                      <h6 data-blue onClick={toggle}>
                        Click here to open Map
                      </h6>
                    </div>
                  </div>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    style={{ maxWidth: "70%" }}
                  >
                    <ModalHeader toggle={toggle}>
                      Locate us easily with Google Maps
                    </ModalHeader>
                    <ModalBody>
                      <div className="map-wrap">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2377.4289282806376!2d-2.2611021840281844!3d53.42503862954498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1e2cf05de4f%3A0xc38be8614ae58197!2s300%20Media!5e0!3m2!1sen!2sin!4v1667973615631!5m2!1sen!2sin"
                          width={"100%"}
                          height={400}
                          style={{ border: 0 }}
                          allowfullscreen=""
                          loading="lazy"
                          title="300media Address"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <CallToAction />
    </>
  );
}

export default Contact;
