import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import GoTop from "../Common/GoTop";

// Image Imports

// import Logo from "../../../images/Logo.svg";
import Logo from "../../../images/logo_300media.svg";
import Phone from "../../../images/Phone.svg";
import Email from "../../../images/Email.svg";
import WhatsApp from "../../../images/WhatsApp.svg";
import Fb from "../../../images/social/Fb.svg";
import Twitter from "../../../images/social/Twitter.svg";
import Linkedin from "../../../images/social/Linkedin.svg";
import Insta from "../../../images/social/Insta.svg";

// End of Image Imports
function Footer() {
  return (
    <footer>
      <div className="container p-0">
        <Row>
          <Col sm="12" md="5" lg="5">
            <img src={Logo} alt="300media" />
            <h6 className="mb-0 mt-2">300 Media Limited</h6>
            <p>
              300 Media Ltd., a company registered in England and Wales. <br />{" "}
              Company Registration: 08835058.
            </p>
            <p className="text-muted">
              Unit 5, Christie Fields Office Park, <br />
              Christie Way, West Didsbury, <br />
              Manchester, M21 7QY
            </p>
          </Col>
          <Col sm="12" md="2" lg="2">
            <h6>Quick Links</h6>
            <ul className="footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">Who we are</Link>
              </li>
              <li>
                <Link to="/contact">Get in touch</Link>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="2" lg="2">
            <h6>Legal</h6>
            <ul className="footer-links">
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/GDPR">GDPR Compliance</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="3" lg="3">
            <div className="footer-contact-details">
              <h6>Phone</h6>
              <p style={{ marginBottom: ".75rem" }}>
                <a href="tel:03308181640">
                  <img src={Phone} alt="Phone" />
                  0330 818 1640
                </a>
              </p>
              <h6>Email</h6>
              <p>
                <a href="mailto:hello@300media.co.uk">
                  <img src={Email} alt="Email" />
                  hello@300media.co.uk
                </a>
              </p>
              <h6>Follow us on Social Media</h6>
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.facebook.com/300media"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Fb} alt="Facebook" />
                  </a>
                </li>
                {/* <li>
                  <img src={Twitter} alt="Twitter" />
                </li> */}
                <li>
                  <a
                    href="https://www.linkedin.com/company/300-media-limited/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Linkedin} alt="LinkedIn" />
                  </a>
                </li>
                {/* <li>
                  <img src={Insta} alt="Instagram" />
                </li> */}
              </ul>
            </div>
          </Col>
        </Row>
        <div className="copy-text">
          <span className="text-muted">
            {new Date().getFullYear()} &copy; 300 Media Private Limited | All
            Rights Reserved
          </span>
          <span className="text-muted">
            Made with ❤️ &amp; Code by{" "}
            <span className="highlight-u text-dark">Team 300 Media</span>
          </span>
        </div>
      </div>
      <GoTop />
    </footer>
  );
}

export default Footer;
