import React, { useEffect } from "react";

import "./Legal.css";

// Image Imports

import PrivacyIcon from "../../../images/Privacy.svg";

// End of Image Imports

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Privacy Policy | 300 Media - Web and Mobile Applications Development";
  }, []);
  return (
    <section className="legal-section container">
      {/* <img src={PrivacyImg} alt="Privacy Policy" width={250} /> */}
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h2>Privacy Policy</h2>
        <img src={PrivacyIcon} alt="Privacy" width="30" />
      </div>
      <hr />
      <div className="legal-wrap">
        <p>
          300 Media Limited is a private limited company registered in England
          and Wales. You can find our registered company information in the
          About Us section of this policy, and contact details on the Contact Us
          page of our website. We operate as a staffing solutions and services
          company, providing temporary staff and related services to private,
          not-for-profit and public sector health and social care organisations.
          Depending on who you are, we may collect, process and share
          information about you, some of which could be sensitive personal
          information.
        </p>
        <h5>How we will use your information</h5>
        <p>
          300 Media Limited will use your information to support your
          application to work with us as a temporary or agency worker, or
          support you in your current role. We will ask you to provide
          information directly and will obtain information about you from other
          sources, such as (but not limited to) the Disclosure and Barring
          Service and your employers. We will combine this information to form a
          detailed picture about you to help determine your suitability to work
          for us.
        </p>
        <p>
          We may also gather information about you to help answer a question you
          may have about our services or about working with us.
        </p>
        <p>
          We would also like to keep your information on file to help support
          our marketing activities, and to forward details of future job
          opportunities to you. If you decide at any time that you would not
          like your information to be used in this way, please send an email to
          info@300media.co.uk and let us know.
        </p>
        <h5>Sharing your information with others</h5>
        <p>
          We will share your information with others when required to do so by
          law, to help ensure the safety of vulnerable children or adults, or to
          support the process of placing you in a job with one of our clients.
        </p>
        <p>
          We will also pass limited information about you, such as your name,
          contact details and bank details, to other companies we work with to
          allow us to pay you and provide other employment related services.
        </p>
        <p>
          We will never sell your information to anyone, or share it in a way
          not described in this notice without your permission.
        </p>
        <h5>Information Security</h5>
        <p>
          We take our responsibilities to protect your privacy very seriously.
          Technical safeguards, such as firewalls, antivirus software, and data
          encryption are used to help ensure that your information is kept safe
          and only disclosed to people who are authorised to view it. Where we
          are required to share your information with third parties, we will
          take reasonable technical and organisational precautions to prevent
          the loss, misuse or alteration of your personal information and we
          will try and ensure that we only share it in ways that are secure,
          such as by encrypted email, or by using recorded post.
        </p>
        <p>
          Unfortunately, information you submit online via our website, or share
          with us by email, can never be 100% secure. Any information you share
          in this way is communicated at your own risk. Where we provide you
          with a secure area of our websites protected by username and password
          you are required to keep those details confidential. Please do not
          share your password with anyone, including members of our staff. We
          will never ask you to share your password with us.
        </p>
        <h5>Visiting our websites and the use of Cookies</h5>
        <p>
          You can visit and browse our websites without disclosing any personal
          information about yourself. Our websites use cookies, which are small
          files of letters and numbers that are stored on your computer if you
          agree. They help us distinguish you from other visitors to our website
          to help us provide you with a good experience when you browse our
          website, and help us to improve it.
        </p>
        <p>
          Some of the cookies we use are analytical cookies that allow us to
          count the number of people who visit our website and see where they
          are visiting from. We use Google Analytics to help us do this.
        </p>
        <h5>Third Party Websites</h5>
        <p>
          Our website may contain links to other sites. We are not responsible
          for the privacy policies or practices of third party sites. If you
          follow a link to any other sites, please note that such sites have
          their own privacy policies and that we do not accept any
          responsibility or liability for their policies. Please check their
          policies before you submit any information to such sites.
        </p>
        <h5>Your right to a copy of the information we hold about you</h5>
        <p>
          You have the right at any time to request a copy of the information we
          hold about you. If you would like to do so, please write to us at the
          address below and include a cheque for £10 made payable to 300 Media
          Limited, which is an administrative fee to help cover our costs. We
          will respond to you within 40 days of receiving your request.
        </p>

        <p>
          Data Subject Access Request <br /> 300 Media Limited <br />2
          Silverwood Avenue, Chorlton-cum-Hardy, Manchester, M21 8BN, United
          Kingdom
        </p>
        <h5>Disposing of your information once we have finished with it</h5>
        <p>
          We will only keep your personal information for as long as is required
          of us by law, or as long as is needed to legitimately support our
          business activities. We will only keep as much information about you
          as is required, and will securely destroy any personal information
          about you when we have finished using it. In most circumstances we
          will destroy the personal information we hold about you 10 years from
          the last date you worked with us.
        </p>
        <h5>About Us</h5>
        <p>
          300 Media Limited is a trading name of 300 Media Limited, a Limited
          Company registered in England and Wales, with registered company
          number 08835058. Our registered offices are at 2 Silverwood Avenue,
          Chorlton-cum-Hardy, Manchester, M21 8BN, United Kingdom.
        </p>
        <p>
          Note that 300 Media has no access to or control over these cookies
          that are used by third-party advertisers.
        </p>
        <h5>Contact</h5>
        <p>
          If you have any questions about this policy or if you wish to make a
          complaint about how we have used your personal information, please
          contact us by email at info@300media.co.uk. If you are not satisfied
          with how we handle your complaint, you are able to make a complaint to
          the Information Commissioner at:
        </p>
        <p>
          <span className="highlight">Head Office</span> <br />
          2 Silverwood Avenue, <br /> Chorlton-cum-Hardy, <br /> Manchester, M21
          8BN, <br /> United Kingdom <br />
          0330 818 1640
        </p>
      </div>
    </section>
  );
}

export default Privacy;
