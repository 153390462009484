import React, { useState, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import toast from "react-hot-toast";
import moment from "moment";
import "./Common.css";

// Image Imports

import Cta from "../../../images/Cta.svg";
import Send from "../../../images/Send.svg";
import BtnLoadingLight from "../../../images/BtnLoadingLight.svg";
import RightArrowLight from "../../../images/RightArrowLight.svg";
import closeIcon from "../../../images/closeIcon.svg";

// End of Image Imports

const initialValues = {
  full_name: "",
  phone_number: "",
  email: "",
  project: "",
};

function CallToAction() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [formWindow, setFormWindow] = useState(false);

  const openProjectForm = () => {
    setFormWindow(!formWindow);
  };

  const closeProjectForm = () => {
    setFormWindow(!formWindow);
    setStyleValue1(0);
    setStyleValue2(0);
    setStyleValue3(0);
    setStyleValue4(0);
    setStyleValue5(0);
  };

  const { full_name, email, phone_number, project } = values;

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    event.persist();

    setValues({ ...values, [name]: value });
  };

  const onChangeNumber = (e, type) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      // Allow only numbers in textbox
      var num = String(e.target.value);
      setValues({
        ...values,
        [e.target.name]: num,
      });
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const submitHandlerCta = (e) => {
    e.preventDefault();
    if (project === "") {
      return toast.error("Please tell us about your project");
    }
    setLoading(true);
    const data = new FormData();
    data.append("Email", email);
    data.append("Contact", phone_number);
    data.append("Name", full_name);
    data.append("Project", project);
    data.append("Timestamp", moment().format("DD-MM-YYYY hh:mm a"));

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbxh6mordBlGoh43fUlCheYXiiRZfk6rvFGh6vY3_BWSHb2FJhdxBesGgt-5bXPjAqJb/exec";
    // const form = document.forms["cta_form"];
    fetch(scriptURL, {
      method: "POST",
      body: data,
    })
      .then(() => {
        setValues(initialValues);
        setLoading(false);
        toast.success(
          "Message sent successfully! We will get back to you shortly!"
        );
        setFormWindow(!formWindow);
        setStyleValue1(0);
        setStyleValue2(0);
        setStyleValue3(0);
        setStyleValue4(0);
        setStyleValue5(0);
      })
      .catch(() => toast.error("Sorry, try again!"));
  };

  // Form States

  // const [opening, setOpening] = useState(0);
  const [styleValue1, setStyleValue1] = useState(0);
  const [styleValue2, setStyleValue2] = useState(0);
  const [styleValue3, setStyleValue3] = useState(0);
  const [styleValue4, setStyleValue4] = useState(0);
  const [styleValue5, setStyleValue5] = useState(0);

  // const firstSection = useRef(null);
  // const secondSection = useRef(null);
  // const thirdSection = useRef(null);
  // const fourthSection = useRef(null);
  // const fifthSection = useRef(null);

  function firstSectionBtn(value) {
    setStyleValue1(value);
    setStyleValue2(value);
    // firstSection.current.classList.add("move-up");
    // secondSection.current.classList.add("move-up");
  }

  function secondSectionBtn(value) {
    if (full_name === "") {
      return toast.error("Please let us know your name");
    }
    setStyleValue2(value);
    setStyleValue3(value);
    // firstSection.current.classList.add("move-up");
    // secondSection.current.classList.add("move-up-2");
    // thirdSection.current.classList.add("move-up-2");
  }

  function thirdSectionBtn(value) {
    if (phone_number === "") {
      return toast.error("Please give us your contact number");
    }
    setStyleValue3(value);
    setStyleValue4(value);
    // thirdSection.current.classList.add("move-up-3");
    // fourthSection.current.classList.add("move-up-3");
  }

  function fourthSectionBtn(value) {
    if (email === "") {
      return toast.error("Email required.", {
        id: "email",
      });
    } else if (!validateEmail(email)) {
      return toast.error("Seems like an invalid email.", {
        id: "email",
      });
    }
    setStyleValue4(value);
    setStyleValue5(value);
    // fourthSection.current.classList.add("move-up-3");
    // fifthSection.current.classList.add("move-up-3");
  }

  function fifthSectionBtn(value) {
    setStyleValue4(value);
    setStyleValue5(value);
    // fourthSection.current.classList.add("move-up-3");
    // fifthSection.current.classList.add("move-up-3");
  }

  return (
    <>
      <section className="cta">
        <div className="container cta-content p-0">
          <Row>
            <Col sm="12" md="8" lg="8">
              <span id="cta-tag">Great minds work together</span>
              <h2>Have a project in mind?</h2>
              <p>
                We invite you to view our services and contact us to start
                taking advantage of{" "}
                <span className="highlight">superior quality</span> and our{" "}
                <span className="highlight">commitment</span> towards it.
              </p>
              <Button className="contact-us-btn" onClick={openProjectForm}>
                Let's Connect
                <img src={Send} alt="Send" />
              </Button>
            </Col>
            <Col
              sm="12"
              md="4"
              lg="4"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="image-holder">
                <img src={Cta} alt="300media" />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className={`form-overlay ${formWindow ? "form-open" : ""}`}>
        <div className="close_form_btn" onClick={closeProjectForm}>
          <span>Close</span>
          <img src={closeIcon} alt="Close" width={20} height={20} />
        </div>
        <div className="container">
          <div className="form-overlay-content">
            <form
              method="POST"
              className="form-slide-container"
              name="cta_form"
              onSubmit={submitHandlerCta}
            >
              <div className="form-box-container">
                <div
                  className="form-box first-section"
                  style={{ top: styleValue1 }}
                  // ref={firstSection}
                >
                  <h1>We are happy for your interest in us!</h1>
                  <h3>
                    Got a project idea? Don't know where and how to start? We
                    can help!
                  </h3>
                  <h6>
                    Please be kind enough to fill in your details and a gist
                    about your project so that we can take it from there.
                  </h6>
                  <Button
                    color="primary"
                    type="button"
                    className="form-btn mt-3"
                    onClick={() => firstSectionBtn("-100%")}
                  >
                    Let's Go
                    <img src={RightArrowLight} alt="Right" />
                  </Button>
                </div>
                <div
                  className="form-box second-section"
                  style={{
                    top: styleValue2 === "-200%" ? "-200%" : styleValue1,
                  }}
                >
                  <h3>Let's get to know about you first! What's your name?</h3>
                  <input
                    type="text"
                    className="form-control"
                    id="full_name"
                    name="full_name"
                    value={full_name}
                    onChange={handleChange}
                    required
                  />
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      color="primary"
                      type="button"
                      className="form-btn"
                      onClick={() => secondSectionBtn("-200%")}
                    >
                      Continue
                      <img src={RightArrowLight} alt="Right" />
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      className="form-btn"
                      style={{ background: "var(--bs-red)" }}
                      onClick={() => firstSectionBtn("0")}
                      // ref={goBtn}
                    >
                      Go Back
                    </Button>
                  </div>
                </div>
                <div
                  className="form-box third-section"
                  style={{
                    top: styleValue3 === "-300%" ? "-300%" : styleValue2,
                  }}
                  // ref={thirdSection}
                >
                  <h3>Your contact number, please?</h3>
                  <input
                    type="text"
                    className="form-control"
                    id="phone_number"
                    name="phone_number"
                    value={phone_number}
                    onChange={onChangeNumber}
                    required
                    maxlength="15"
                  />
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      color="primary"
                      type="button"
                      className="form-btn"
                      onClick={() => thirdSectionBtn("-300%")}
                      // ref={goBtn}
                    >
                      Continue
                      <img src={RightArrowLight} alt="Right" />
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      className="form-btn"
                      style={{ background: "var(--bs-red)" }}
                      onClick={() => secondSectionBtn("-100%")}
                      // ref={goBtn}
                    >
                      Go Back
                    </Button>
                  </div>
                </div>
                <div
                  className="form-box fourth-section"
                  style={{
                    top: styleValue4 === "-400%" ? "-400%" : styleValue3,
                  }}
                  // ref={fourthSection}
                >
                  <h3>And your Email? We can discuss and stay in touch!</h3>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    required
                  />
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      color="primary"
                      type="button"
                      className="form-btn"
                      onClick={() => fourthSectionBtn("-400%")}
                      // ref={goBtn}
                    >
                      Continue
                      <img src={RightArrowLight} alt="Right" />
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      className="form-btn"
                      style={{ background: "var(--bs-red)" }}
                      onClick={() => thirdSectionBtn("-200%")}
                      // ref={goBtn}
                    >
                      Go Back
                    </Button>
                  </div>
                </div>
                <div
                  className="form-box fifth-section"
                  style={{
                    top: styleValue5 === "-400%" ? "-400%" : styleValue4,
                  }}
                  // ref={fifthSection}
                >
                  <h3>
                    We are excited to know about your project! Please tell us in
                    a nutshell.
                  </h3>
                  <textarea
                    className="form-control"
                    id="project_textarea"
                    name="project"
                    value={project}
                    onChange={handleChange}
                    rows="10"
                    maxlength="500"
                  />
                  <span>Max 500 characters</span>
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      color="primary"
                      type="submit"
                      className="form-btn"
                      onClick={submitHandlerCta}
                      // ref={goBtn}
                    >
                      {loading ? "Sending..." : "Send us"}
                      <img
                        src={loading ? BtnLoadingLight : Send}
                        className={`${loading ? "rotate_load" : ""}`}
                        alt="Send"
                      />
                    </Button>
                    <Button
                      color="danger"
                      type="button"
                      className="form-btn"
                      style={{ background: "var(--bs-red)" }}
                      onClick={() => fifthSectionBtn("-300%")}
                      // ref={goBtn}
                    >
                      Go Back
                    </Button>
                  </div>
                </div>
              </div>
              {/* <Button color="primary" className="contact-us-btn">
                Send
              </Button> */}
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default CallToAction;
