import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "./Legal.css";

// Image Imports

import TermsIcon from "../../../images/Terms.svg";

// End of Image Imports

function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Terms & Conditions | 300 Media - Web and Mobile Applications Development";
  }, []);
  return (
    <section className="legal-section container">
      {/* <img src={TermsImg} alt="Terms &amp; Conditions" width={250} /> */}
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h2>Terms &amp; Conditions</h2>
        <img src={TermsIcon} alt="Terms" width="30" />
      </div>
      <hr />
      <div className="legal-wrap">
        <p>
          These terms and conditions outline the rules and regulations for the
          use of <span className="highlight">300 Media Limited</span>
          's Website, located at{" "}
          <a
            href="https://www.300media.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.300media.co.uk.{" "}
          </a>
          These terms of website use (together with the documents referred to in
          it) tell you how you are permitted to use our Site. If you continue to
          browse and use this Site you confirm that you accept these terms of
          use and agree to be bound by them. If you do not agree to these terms
          of use, you must not use our Site. Unauthorised use of our Site may
          give rise to a claim for damages and / or be a criminal offence. The
          term “300 Media Limited” or “us” or “we” refers to the owners of the
          site, 300 Media Limited, a Limited Company registered in England and
          Wales with company number 08835058. We have our registered offices at
          2 Silverwood Avenue, Chorlton-cum-Hardy, Manchester, M21 8BN, United
          Kingdom. The term “you” refers to the users or viewer of our site.
          Other Applicable Terms – These terms of use incorporate the following
          additional terms, which also apply to your use of our Site. Our
          Privacy Policy sets out the terms on which we process any personal
          data we collect from you, or that you provide to us.
        </p>
        <p>
          By using our Site, you consent to such processing and warrant that all
          data provided by you is accurate. Our Acceptable Use Policy sets out
          the permitted uses and prohibited uses of our site. When using our
          site, you must comply with this Acceptable Use Policy. Information
          about us 300media.co.uk (“Site”) is a website operated by 300 Media
          Limited. We are a staffing solutions and services company, providing
          temporary staff and related services to private, not-for-profit, and
          public sector health and social care.
        </p>
        <p>
          Our Privacy Policy sets out the terms on which we process any personal
          data we collect from you, or that you provide to us. By using our
          Site, you consent to such processing and warrant that all data
          provided by you is accurate. Our Acceptable Use Policy sets out the
          permitted uses and prohibited uses of our site. When using our site,
          you must comply with this Acceptable Use Policy. Information about us
          300media.co.uk (“Site”) is a website operated by 300 Media Limited. We
          are a staffing solutions and services company, providing temporary
          staff and related services to private, not-for-profit, public sector
          health and social care organisations. We are a Limited Company.
        </p>
        <h5>Changes to these terms</h5>
        <p>
          We may revise these terms of use at any time by amending this page.
          Please check this page from time to time to take notice of any changes
          we have made, as they are binding on you.
        </p>
        <h5>Changes to our website</h5>
        <p>
          We may update our site from time to time, and may change the content
          at any time without notice.
        </p>
        <h5>Your account and password</h5>
        <p>
          If you choose, or are provided with, a user identification code,
          password, or any other piece of information as part of our security
          procedures, you must treat such information as confidential. You must
          not disclose it to any third party. We have the right to disable any
          user identification code or password, whether chosen by you or
          allocated by us, at any time, if in our reasonable opinion you have
          failed to comply with any of the provisions of these terms of use. If
          you know or suspect that anyone other than you knows your user
          identification code or password, you must promptly notify us at.
        </p>
        <p>
          If you choose, or are provided with, a user identification code,
          password, or any other piece of information as part of our security
          procedures, you must treat such information as confidential. You must
          not disclose it to any third party. We have the right to disable any
          user identification code or password, whether chosen by you or
          allocated by us, at any time, if in our reasonable opinion you have
          failed to comply with any of the provisions of these terms of use. If
          you know or suspect that anyone other than you knows your user
          identification code or password, you must promptly notify us at
          hello@300media.co.uk.
        </p>
        <h5>No reliance on information</h5>
        <p>
          The content on our Site is provided for general information only. It
          is not intended to amount to advice on which you should rely. Your use
          of any information or materials on this Site is entirely at your own
          risk, for which we shall not be liable. It shall be your own
          responsibility to ensure that any products, services or information
          available through this Site meet your specific requirements. You
          should obtain professional or specialist advice before taking, or
          refraining from, any action on the basis of the content of our Site.
          Although we make reasonable efforts to update the information on our
          Site, we make no representations, warranties or guarantees, whether
          expressed or implied, that the content on our Site is accurate,
          complete or up-to-date or as to the completeness or suitability of the
          information and materials found or offended on our Site for any
          particular purpose. We are also under no obligation to update it. You
          acknowledge that such information and materials may contain
          inaccuracies or errors and we expressly exclude liability for any such
          inaccuracies or errors to the fullest extent permitted by law. Any
          photos are staged and not real unless stated otherwise.
        </p>
        <h5>Accessing our site</h5>
        <p>
          Our site is made available free of charge. We do not guarantee that
          our Site, or any content on it, will always be available or be
          uninterrupted. Access to our Site is permitted on a temporary basis.
          We may suspend, withdraw, discontinue or change all or any part of our
          Site without notice. We will not be liable to you if for any reason
          our Site is unavailable at any time or for any period. You are
          responsible for making all arrangements necessary for you to have
          access to our Site. You are also responsible for ensuring that all
          persons who access our Site through your internet connection are aware
          of these terms of use and other applicable terms and conditions, and
          that they comply with them.
        </p>
        <h5>Linking to our Site</h5>
        <p>
          You may not create a link to our Site from another website or document
          without our prior written consent. To obtain such consent, please
          contact hello@300media.co.uk. If we provide you with such consent to
          create a link to our Site, the website in which you are linking must
          comply in all respects with the content standards set out in our
          Acceptable Use Policy. We reserve the right to withdraw linking
          permission without notice.
        </p>
        <h5>Intellectual Property Rights</h5>
        <p>
          We are the owner or the licensee of all intellectual property rights
          in our Site, and in the material published on it. Those works are
          protected by copyright laws and treaties around the world. All such
          rights are reserved. You may print off one copy, and may download
          extracts, of any page(s) from our Site for your personal use and you
          may draw the attention of others within your organisation to content
          posted on our Site. You must not modify the paper or digital copies of
          any materials you have printed off or downloaded in any way, and you
          must not use any illustrations, photographs, video or audio sequences
          or any graphics separately from any accompanying text. Our status (and
          that of any identified contributors) as the authors of content on our
          Site must always be acknowledged. You must not use any part of the
          content on our Site for commercial purposes without obtaining a
          licence to do so from us or our licensors. If you print off, use, copy
          or download any part of our Site in breach of these terms of use, your
          right to use our Site will cease immediately and you must, at our
          option, return or destroy any copies of the materials you have made.
        </p>
        <h5>Uploading Content to our Site</h5>
        <p>
          Whenever you make use of a feature that allows you to upload content
          to our Site such as our forums or chat-rooms, or to make contact with
          other users of our Site, you must comply with the content standards
          set out in our Acceptable Use Policy. You warrant that any such
          contribution does comply with those standards, and you will be liable
          to us and indemnify us for any breach of that warranty. Any content
          you upload to our Site will be considered non-confidential and
          non-proprietary, and we have the right to use, copy, distribute and
          disclose to third parties any such content for any purpose. We also
          have the right to disclose your identity to any third party who is
          claiming that any content posted or uploaded by you to our Site
          constitutes a violation of their intellectual property rights, or of
          their right to privacy. We will not be responsible, or liable to any
          third party, for the content or accuracy of any content posted by you
          or any other user of our Site. We have the right to remove any posting
          you make on our Site if, in our opinion, your post does not comply
          with the content standards set out in our Use Policy. The views
          expressed by other users on our Site do not represent our views or
          values.
        </p>
        <h5>Viruses</h5>
        <p>
          We do not guarantee that our Site will be secure or free from bugs or
          viruses. You are responsible for configuring your information
          technology, computer programmes and platform in order to access our
          Site. You should use your own virus protection software. You must not
          misuse our Site by knowingly introducing viruses, Trojans, worms,
          logic bombs or other material which is malicious or technologically
          harmful. You must not attempt to gain unauthorised access to our Site,
          the server on which our Site is stored or any server, computer or
          database connected to our Site. You must not attack our site via a
          denial-of-service attack or a distributed denial-of service attack. By
          breaching this provision, you would commit a criminal offence under
          the Computer Misuse Act 1990 act/or any other laws that supersedes
          such Act. We will report any such breach to the relevant law
          enforcement authorities and we will co-operate with those authorities
          by disclosing your identity to them. In the event of such a breach,
          your right to use our Site will cease immediately.
        </p>
        <h5>Limitation of Our Liability</h5>
        <p>
          Nothing in these terms of use excludes or limits our liability for
          death or personal injury arising from our negligence, or our fraud or
          fraudulent misrepresentation, or any other liability that cannot be
          excluded or limited by English law. To the extent permitted by law, we
          exclude all conditions, warranties, representations or other terms
          which may apply to our Site or any content on it, whether expressed or
          implied. We will not be liable to any user for any loss or damage,
          whether in contract, tort (including negligence), breach of statutory
          duty, or otherwise, even if foreseeable, arising under or in
          connection with: use of, or inability to use, our Site; or use of or
          reliance on any content displayed on our Site. If you are a business
          user, please note that in particular, we will not be liable for:
        </p>
        <ul>
          <li>loss of profits, sales, business, or revenue; or</li>
          <li>punitive or consequential loss or damages; or</li>
          <li>business interruption; or</li>
          <li>loss of anticipated savings; or</li>
          <li>loss of business opportunity, goodwill or reputation; or</li>
          <li>loss connected in any way to business interruption,</li>
        </ul>
        <p>
          In connection with the use, inability to use or the results of use of
          the Site including on account of your access to, use of, browsing, or
          your downloading of any material from the Site that may infect your
          computer equipment, computer programs, data or other proprietary
          content. For the avoidance of doubt, any links should not be
          interpreted as endorsement by us of those linked websites (unless such
          link related to the business of a company within our group). We have
          no responsibility for the content of the linked website of third
          parties. We will not be liable for any loss or damage that may arise
          from your use of them.
        </p>
        <h5>Applicable Law</h5>
        <p>
          These terms shall be governed by the laws of England and Wales and
          each party agrees to submit to the exclusive jurisdiction of England
          and Wales.
        </p>
        <h5>Trade Marks</h5>
        <p>
          All registered and unregistered trademarks produced on this website
          are our property unless otherwise stated. All trademarks reproduced in
          this website, which are not the property of, or licensed to the
          operator, are acknowledged on the website.
        </p>
        <h5>Contact Us</h5>
        <p>
          To contact us, please email hello@300media.co.uk. Thank you for
          visiting our site.
        </p>
      </div>
    </section>
  );
}

export default Terms;
