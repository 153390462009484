import React, { useState } from "react";

// import Logo from "../../../images/Logo.svg";
import Logo from "../../../images/logo_300media.svg";
// import Logo from "../../../images/LogoChristmas.svg";
import PreloaderImg from "../../../images/Preloader.gif";

function Preloader() {
  const [loading, setLoading] = useState(true);

  window.onload = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <div className={`preloader ${loading ? "" : "pre-hide"}`}>
      <div className="preloader-content">
        <img src={Logo} alt="Logo" />
        <img src={PreloaderImg} alt="Preloader" id="pre" />
      </div>
    </div>
  );
}

export default Preloader;
