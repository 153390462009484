import React from "react";

// Image Imports

import Gotop from "../../../images/Gotop.svg";

// End of Image Imports

function GoTop() {
  const goTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <img className="go_top" src={Gotop} alt="Go to Top" onClick={goTop} />
    </div>
  );
}

export default GoTop;
