import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, NavbarToggler, Collapse, Nav, NavItem } from "reactstrap";

import "./Common.css";

// Image Imports

// import Logo from "../../../images/Logo.svg";
import Logo from "../../../images/logo_300media.svg";
// import Logo from "../../../images/LogoChristmas.svg";

// End of Image Imports

function Header() {
  const [nav, setNav] = useState(false);

  const noRefCheck = () => {
    setNav(!nav);
  };

  const [scrollPosition, setScrollPosition] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    console.log(position);
    if (position >= 300) return setScrollPosition(!scrollPosition);
    else return setScrollPosition(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeClass, setActiveClass] = useState(window.location.pathname);

  const setActive = (pathname) => {
    setActiveClass(pathname);
  };

  return (
    <div>
      <div className="top-bar">
        <ul>
          <li>
            <ion-icon name="mail-unread"></ion-icon>
            <a
              href="https://webmail.300media.co.uk/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Webmail
            </a>
          </li>
          <li>
            <ion-icon name="log-in"></ion-icon>
            <a
              href="https://cp.300media.co.uk/basket-summary-login?r=%2Fmanage"
              rel="noopener noreferrer"
              target="_blank"
            >
              cPanel and Support
            </a>
          </li>
        </ul>
      </div>
      <Navbar
        color="white"
        expand="md"
        fixed="top"
        container
        light
        className={`px-0 py-3 ${scrollPosition ? "scrolled" : ""}`}
      >
        <Link to="/" className="pt-0 navbar-brand">
          <img src={Logo} alt="300media" width="100" />
        </Link>
        <NavbarToggler className="me-2" onClick={noRefCheck} />
        <Collapse
          className={`justify-content-end ${nav ? "d-block" : ""}`}
          navbar
        >
          <Nav className="mt-1" navbar>
            <NavItem onClick={() => setActive("/")}>
              <NavLink
                className={`nav-link ${activeClass === "/" ? "active" : ""}`}
                to="/"
                onClick={noRefCheck}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem onClick={() => setActive("/services")}>
              <NavLink
                className={`nav-link ${
                  activeClass === "/services" ? "active" : ""
                }`}
                to="/services"
                onClick={noRefCheck}
              >
                Services
              </NavLink>
            </NavItem>
            <NavItem onClick={() => setActive("/seo")}>
              <NavLink
                className={`nav-link ${
                  activeClass === "/seo" ? "active" : ""
                }`}
                to="/seo"
                onClick={noRefCheck}
              >
                SEO
              </NavLink>
            </NavItem>
            <NavItem onClick={() => setActive("/projects")}>
              <NavLink
                className={`nav-link ${
                  activeClass === "/projects" ? "active" : ""
                }`}
                to="/projects"
                onClick={noRefCheck}
              >
                Projects
              </NavLink>
            </NavItem>
            {/* <NavItem onClick={() => setActive("/careers")}>
              <Link
                className={`nav-link ${
                  activeClass === "/careers" ? "active" : ""
                }`}
                to="/careers"
                onClick={noRefCheck}
              >
                Careers
              </Link>
            </NavItem> */}
            <NavItem onClick={() => setActive("/about")}>
              <NavLink
                className={`nav-link ${
                  activeClass === "/about" ? "active" : ""
                }`}
                to="/about"
                onClick={noRefCheck}
              >
                Who we are
              </NavLink>
            </NavItem>
            <NavItem onClick={() => setActive("/contact")} id="contactLink">
              <NavLink
                className={`nav-link ${
                  activeClass === "/contact" ? "active" : ""
                }`}
                to="/contact"
                onClick={noRefCheck}
              >
                Get in touch
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
