import React, { useEffect } from "react";
import { Row, Col, UncontrolledCarousel } from "reactstrap";

import "./Projects.css";

import ProjectImg from "../../../images/projects/projects.svg";
import camimg1 from "../../../images/projects/cam/1.jpg";
import camimg2 from "../../../images/projects/cam/2.jpeg";
import camimg3 from "../../../images/projects/cam/3.jpg";
import logoCAM from "../../../images/projects/cam/logo.png";
import edrimg1 from "../../../images/projects/edr/1.png";
import edrimg2 from "../../../images/projects/edr/2.png";
import edrimg3 from "../../../images/projects/edr/3.png";
import logoEDR from "../../../images/projects/edr/logo.png";
import kgimg1 from "../../../images/projects/kg/1.jpeg";
import kgimg2 from "../../../images/projects/kg/2.jpeg";
import kgimg3 from "../../../images/projects/kg/3.jpeg";
import logoKG from "../../../images/projects/kg/logo.png";
import fcimg1 from "../../../images/projects/fc/1.png";
import fcimg2 from "../../../images/projects/fc/2.png";
import fcimg3 from "../../../images/projects/fc/3.png";
import logoFC from "../../../images/projects/fc/logo.svg";
import node from "../../../images/projects/node.svg";
import react from "../../../images/projects/react.svg";
import stripe from "../../../images/projects/stripe.svg";
import aws from "../../../images/projects/aws.svg";

import ProjectHeader from "../Common/ProjectHeader";
import CallToAction from "../Common/CallToAction";

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Our Projects | 300 Media - Web and Mobile Applications Development";
  }, []);
  return (
    <>
      <section className="project-main-container">
        <ProjectHeader title="Projects" img={ProjectImg} />
        <div className="project-item-wrap">
          <div className="container">
            <h3 className="mb-3">Some of our exciting projects you may love</h3>
            <h6>
              Please look at some of our existing projects. These will allow you
              to understand how we develop outstanding web and mobile
              applications. Digital transformation enables our clients to reduce
              their recurring costs and helps them provide a hassle-free
              experience to their customers. We solve their real-world problems
              and provide accurate, optimised solutions to generate better leads
              and grow their business exponentially.
            </h6>
            <h6>
              We have multiple web and mobile applications underway. We are
              building interactive mobile apps to solve customer problems.{" "}
              <span className="highlight-u">Build yours today!</span>
            </h6>
            <div className="project-item">
              <Row>
                <Col sm="12" lg="7" md="7">
                  <div className="project-content">
                    <h5>
                      <span className="highlight-u">Care Agency Media</span>
                    </h5>
                    <h6>
                      We presented an end-to-end service platform specifically
                      designed for an established and well-renowned healthcare
                      consultancy company.
                    </h6>
                    <p>
                      <span className="highlight">Care Agency Media (CAM)</span>{" "}
                      is an expert in the healthcare industry in the United
                      Kingdom. It has helped many healthcare agencies set up and
                      grow their companies over the years. They focus on helping
                      people within various care sectors, including recruitment,
                      employment, care agencies or social care businesses. Over
                      the years as a marketing and consultancy company, they
                      have been lucky enough to work with several start-ups and
                      established healthcare agencies, helping them start and
                      grow their businesses.
                    </p>
                    <p>
                      Until recently, CAM was using various platforms to
                      organise their work for their clients and staff. 300 Media
                      designed and developed the end-to-end platform for CAM as
                      envisioned for its staff, management, and clients.
                    </p>
                    <p>
                      With the newly developed system by 300 Media, the team at
                      CAM were able to work and provide all their client
                      services on one platform. This permits staff to
                      communicate between themselves and with clients. It allows
                      them to source their required items or stationery, get
                      tenders written and book consultancy sessions and
                      workshops with training teams.
                    </p>
                    <p>
                      The project is live and is used by CAM with over 100
                      clients.
                    </p>
                  </div>
                </Col>
                <Col sm="12" lg="5" md="5">
                  <div className="project-image-wrap">
                    <UncontrolledCarousel
                      indicators={false}
                      controls={false}
                      items={[
                        {
                          altText: "Slide 1",
                          key: 1,
                          src: camimg1,
                        },
                        {
                          altText: "Slide 2",
                          key: 2,
                          src: camimg2,
                        },
                        {
                          altText: "Slide 3",
                          key: 3,
                          src: camimg3,
                        },
                      ]}
                    />
                  </div>
                  <div className="project-stat-wrap">
                    <div className="pst-container">
                      <div className="pst-item">
                        <h6>July, 2021</h6>
                        <span>Started</span>
                      </div>
                      <div className="pst-item">
                        <h6>Live</h6>
                        <span>Status</span>
                      </div>
                      <div className="pst-item">
                        <h6>1000+</h6>
                        <span>Hours Spent</span>
                      </div>
                      <div className="pst-item">
                        <h6>Healthcare</h6>
                        <span>Sector</span>
                      </div>
                      <div className="pst-item">
                        <h6>
                          <img src={react} alt="ReactJS" />
                          <img src={node} alt="NodeJS" />
                          <img src={aws} alt="AWS" />
                          <img src={stripe} alt="Stripe" />
                        </h6>
                        <span>Technologies Used</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="project-item">
              <Row>
                <Col sm="12" lg="7" md="7">
                  <div className="project-content">
                    <h5>
                      <span className="highlight-u">Escape Debt Review</span>
                    </h5>
                    <h6>
                      We provided an end-to-end service platform designed
                      explicitly for Escape Debt customers, aiding them to
                      eliminate their financial struggles and work towards
                      becoming debt-free.
                    </h6>
                    <p>
                      <span className="highlight">Escape Debt Review</span> is a
                      platform created to connect customers who need to get out
                      of a debt review with the aid of experienced debt
                      attorneys. Many people in South Africa are stuck under
                      debt review, which can apply harmful listings to their
                      names. Furthermore, people can be incorrectly advised when
                      applying for a debt review and should not even be under
                      one in the first place. Some find themselves in a
                      downwards debt spiral after years of undergoing a debt
                      review, and when finally, they become debt-free, they
                      still encounter difficulty with their name attached to the
                      process. 300 Media have designed and developed an
                      end-to-end website platform to help customers get their
                      name cleared, allowing them to start afresh and gain
                      access to credit again. This platform makes it easier for
                      them to go through all these processes online, with system
                      and ED review teams helping their customers through this
                      newly developed system.
                    </p>
                    <p>
                      This project has gone live and is helping hundreds of
                      South African customers finally become debt-free and
                      remove their debt review flag on their credit review
                      profile. This allows clients to clear their name and start
                      afresh, and should they choose gain access to credit
                      again.
                    </p>
                  </div>
                </Col>
                <Col sm="12" lg="5" md="5">
                  <div className="project-image-wrap">
                    <UncontrolledCarousel
                      indicators={false}
                      controls={false}
                      items={[
                        {
                          altText: "Slide 1",
                          key: 1,
                          src: edrimg1,
                        },
                        {
                          altText: "Slide 2",
                          key: 2,
                          src: edrimg2,
                        },
                        {
                          altText: "Slide 3",
                          key: 3,
                          src: edrimg3,
                        },
                      ]}
                    />
                  </div>
                  <div className="project-stat-wrap">
                    <div className="pst-container">
                      <div className="pst-item">
                        <h6>April, 2021</h6>
                        <span>Started</span>
                      </div>
                      <div className="pst-item">
                        <h6>Live</h6>
                        <span>Status</span>
                      </div>
                      <div className="pst-item">
                        <h6>250+</h6>
                        <span>Hours Spent</span>
                      </div>
                      <div className="pst-item">
                        <h6>Legal</h6>
                        <span>Sector</span>
                      </div>
                      <div className="pst-item">
                        <h6>
                          <img src={react} alt="ReactJS" />
                          <img src={node} alt="NodeJS" />
                          <img src={aws} alt="AWS" />
                          {/* <img src={stripe} alt="Stripe" /> */}
                        </h6>
                        <span>Technologies Used</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="project-item">
              <Row>
                <Col sm="12" lg="7" md="7">
                  <div className="project-content">
                    <h5>
                      <span className="highlight-u">Kingdom Granites</span>
                    </h5>
                    <h6>
                      An end-to-end service platform specially made for Kingdom
                      Granites and its services.
                    </h6>
                    <p>
                      <span className="highlight">Kingdom Granites</span> is one
                      of the leading organisations in Cape Town, striving to
                      bring the best possible granite worktops to you. The team
                      of Kingdom Granites wanted to develop a website for them
                      to sell their products and services. 300 Media designed
                      and developed an end-to-end website platform for Kingdom
                      Granites to display their products and services for sale.
                      With this newly developed platform, the customers can
                      easily view the list of products and services offered by
                      Kingdom Granites, and they can request a quote for their
                      requirements.
                    </p>
                    <p>
                      The newly designed website by 300 Media specialises in
                      customer-centric UI, which helps the customers quickly
                      access and navigate through the website to get what they
                      need. The project is live and has been used by several
                      customers, and it seamlessly provides services and
                      granites to the customers.
                    </p>
                  </div>
                </Col>
                <Col sm="12" lg="5" md="5">
                  <div className="project-image-wrap">
                    <UncontrolledCarousel
                      indicators={false}
                      controls={false}
                      items={[
                        {
                          altText: "Slide 1",
                          key: 1,
                          src: kgimg1,
                        },
                        {
                          altText: "Slide 2",
                          key: 2,
                          src: kgimg2,
                        },
                        {
                          altText: "Slide 3",
                          key: 3,
                          src: kgimg3,
                        },
                      ]}
                    />
                  </div>
                  <div className="project-stat-wrap">
                    <div className="pst-container">
                      <div className="pst-item">
                        <h6>April, 2021</h6>
                        <span>Started</span>
                      </div>
                      <div className="pst-item">
                        <h6>Live</h6>
                        <span>Status</span>
                      </div>
                      <div className="pst-item">
                        <h6>300+</h6>
                        <span>Hours Spent</span>
                      </div>
                      <div className="pst-item">
                        <h6>Granites &amp; Marbles</h6>
                        <span>Sector</span>
                      </div>
                      <div className="pst-item">
                        <h6>
                          <img src={react} alt="ReactJS" />
                          <img src={node} alt="NodeJS" />
                          <img src={aws} alt="AWS" />
                          {/* <img src={stripe} alt="Stripe" /> */}
                        </h6>
                        <span>Technologies Used</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="project-item">
              <Row>
                <Col sm="12" lg="7" md="7">
                  <div className="project-content">
                    <h5>
                      <span className="highlight-u">Famili Cloud</span>
                    </h5>
                    <h6>
                      A unique, end-to-end platform to discover family history
                      and genealogy.
                    </h6>
                    <p>
                      <span className="highlight">Famili Cloud</span> is a
                      unique family history website that offers members access
                      to over a million searchable UK & South Africa family
                      history records. Its extensive collection enables members
                      to explore their family history using censuses, the fully
                      indexed birth, marriage and death records, passenger
                      lists, the British phone books, military, and parish
                      records. Members can also explore names in other family
                      trees and upload photographs of their own, connecting to
                      millions of other members making their discoveries.
                    </p>
                    <p>
                      The development of this project is underway and is
                      expected to go live by Spring 2022.
                    </p>
                  </div>
                </Col>
                <Col sm="12" lg="5" md="5">
                  <div className="project-image-wrap">
                    <UncontrolledCarousel
                      indicators={false}
                      controls={false}
                      items={[
                        {
                          altText: "Slide 1",
                          key: 1,
                          src: fcimg1,
                        },
                        {
                          altText: "Slide 2",
                          key: 2,
                          src: fcimg2,
                        },
                        {
                          altText: "Slide 3",
                          key: 3,
                          src: fcimg3,
                        },
                      ]}
                    />
                  </div>
                  <div className="project-stat-wrap">
                    <div className="pst-container">
                      <div className="pst-item">
                        <h6>August, 2021</h6>
                        <span>Started</span>
                      </div>
                      <div className="pst-item">
                        <h6>Development Underway</h6>
                        <span>Status</span>
                      </div>
                      <div className="pst-item">
                        <h6>50+</h6>
                        <span>Hours Spent</span>
                      </div>
                      <div className="pst-item">
                        <h6>Social Media</h6>
                        <span>Sector</span>
                      </div>
                      <div className="pst-item">
                        <h6>
                          <img src={react} alt="ReactJS" />
                          <img src={node} alt="NodeJS" />
                          <img src={aws} alt="AWS" />
                          {/* <img src={stripe} alt="Stripe" /> */}
                        </h6>
                        <span>Technologies Used</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <CallToAction />
    </>
  );
}

export default Projects;
